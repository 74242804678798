import React from "react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Report from '../Report/Report';
import ReactDOM from 'react-dom';
import CoverReport from '../../components/Roport/CoverReport';
import generalCoverImage from "../../assets/report_user/general_cover.jpg";

function MyReportList({ listData }) {
    console.log(listData, ':::listData');
    const handleDownloadPDF = async (item) => {
        console.log(item, ':::item');
        // story 타입이 아닌 경우 다른 로직 실행
        if (item.goods_type !== "story") {
            const pdf = new jsPDF('p', 'mm', 'a4');
            
            try {
                const tempDiv = document.createElement('div');
                tempDiv.style.position = 'absolute';
                tempDiv.style.left = '-9999px';
                tempDiv.style.top = '-9999px';
                document.body.appendChild(tempDiv);
                
                // 표지 페이지 렌더링
                ReactDOM.render(
                    <CoverReport 
                        reportData={item} 
                        listData={listData}
                    />, 
                    tempDiv
                );

                await new Promise(resolve => setTimeout(resolve, 1000));

                // 표지 페이지 캡처
                const coverCanvas = await html2canvas(tempDiv, {
                    scale: 2,
                    useCORS: true,
                    logging: false,
                    backgroundColor: null
                });

                const coverImgData = coverCanvas.toDataURL('image/png');
                const coverImgWidth = 210;
                const coverImgHeight = (coverCanvas.height * coverImgWidth) / coverCanvas.width;

                // 표지 페이지 추가
                pdf.addImage(coverImgData, 'PNG', 0, 0, coverImgWidth, coverImgHeight);

                // 일반검사 타입일 경우 두 번째 페이지에 일반검사표지 추가
                if (item.goods_type === 'general') {
                    pdf.addPage();
                    const img = new Image();
                    img.src = generalCoverImage;

                    await new Promise((resolve, reject) => {
                        img.onload = resolve;
                        img.onerror = reject;
                    });

                    const imgCanvas = document.createElement('canvas');
                    const imgCtx = imgCanvas.getContext('2d');
                    imgCanvas.width = img.width;
                    imgCanvas.height = img.height;
                    imgCtx.drawImage(img, 0, 0);

                    const imgData = imgCanvas.toDataURL('image/png');
                    pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4 크기로 설정
                }

                pdf.save(`${item.goods_name}.pdf`);
                document.body.removeChild(tempDiv);

            } catch (error) {
                console.error('PDF 생성 중 오류:', error);
                alert('PDF 생성에 실패했습니다. 다시 시도해주세요.');
            }
            return;
        }

        // 기존의 story type PDF 생성 로직
        const pdf = new jsPDF('p', 'mm', 'a4');
        
        try {
            // Report 컴포넌트 미리 렌더링
            const tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.left = '-9999px';
            tempDiv.style.top = '-9999px';
            document.body.appendChild(tempDiv);
            
            ReactDOM.render(
                <Report 
                    reportData={item} 
                    listData={listData}
                />, 
                tempDiv
            );

            await new Promise(resolve => setTimeout(resolve, 1000));

            const reportCanvas = await html2canvas(tempDiv, {
                scale: 2,
                useCORS: true,
                logging: false,
                backgroundColor: null,
                windowWidth: document.body.scrollWidth,
                windowHeight: tempDiv.scrollHeight,
                onclone: (clonedDoc) => {
                    const splitHeight = Math.floor(tempDiv.scrollHeight * 0.7);
                    const elements = clonedDoc.getElementsByTagName('*');
                    
                    Array.from(elements).forEach(element => {
                        const rect = element.getBoundingClientRect();
                        const elementBottom = rect.bottom;
                        const elementTop = rect.top;
                        
                        // 분할 지점 주변 (위아래 100px) 요소들만 처리
                        if (Math.abs(elementBottom - splitHeight) < 100 || Math.abs(elementTop - splitHeight) < 100) {
                            // 기존 스타일 저장
                            const originalBorder = element.style.border;
                            const originalBackground = element.style.backgroundImage;
                            
                            // PDF 생성을 위한 임시 스타일 적용
                            element.style.border = 'none';
                            element.style.borderTop = 'none';
                            element.style.borderBottom = 'none';
                            
                            // 분할 지점에서만 background-image 제거
                            if (Math.abs(elementBottom - splitHeight) < 50) {
                                element.style.backgroundImage = 'none';
                            }
                            
                            // 나머지 부분은 원래 스타일 유지
                            setTimeout(() => {
                                element.style.border = originalBorder;
                                element.style.backgroundImage = originalBackground;
                            }, 0);
                        }
                    });
                }
            });

            const reportImgData = reportCanvas.toDataURL('image/png');
            const reportImgWidth = 210 * 1;
            const reportImgHeight = (reportCanvas.height * reportImgWidth) / reportCanvas.width;
            const xOffset = (210 - reportImgWidth) / 2;
            const pageHeight = 297; // A4 높이 (mm)

            // 동적 분할 지점 찾기 함수
            const findSplitPoint = (canvas, maxHeight) => {
                const pixelsPerMM = canvas.height / reportImgHeight;
                const maxPixelHeight = maxHeight * pixelsPerMM;
                
                const sections = tempDiv.querySelectorAll('.report-wrapper, .report-table');
                let optimalSplitPoint = canvas.height * 0.76; // 기본값

                for (let section of sections) {
                    const rect = section.getBoundingClientRect();
                    const sectionBottom = rect.bottom * (canvas.height / tempDiv.offsetHeight);
                    
                    if (sectionBottom < maxPixelHeight && sectionBottom > optimalSplitPoint) {
                        optimalSplitPoint = sectionBottom;
                    }
                }

                return optimalSplitPoint;
            };

            // 분할 지점 계산
            const splitPoint = findSplitPoint(reportCanvas, pageHeight);

            // 1페이지: report1 이미지
            try {
                const report1ImagePath = await import(`../../assets/report_1/${item.sort_num}.png`).then(module => module.default);
                const report1Img = new Image();
                report1Img.src = report1ImagePath;

                await new Promise((resolve, reject) => {
                    report1Img.onload = resolve;
                    report1Img.onerror = reject;
                });

                const report1Canvas = document.createElement('canvas');
                const report1Ctx = report1Canvas.getContext('2d');
                const a4WidthInPixels = 210 * 3.78;
                report1Canvas.width = a4WidthInPixels;
                report1Canvas.height = (report1Img.height * report1Canvas.width) / report1Img.width;
                report1Ctx.drawImage(report1Img, 0, 0, report1Canvas.width, report1Canvas.height);

                const report1ImgData = report1Canvas.toDataURL('image/png');
                pdf.addImage(report1ImgData, 'PNG', 0, 0, report1Canvas.width / 3.78, report1Canvas.height / 3.78);
            } catch (error) {
                console.log('report_1 이미지를 찾을 수 없습니다.');
            }

            // 2페이지: report 이미지
            pdf.addPage();
            try {
                const imagePath = await import(`../../assets/report/${item.sort_num}.png`).then(module => module.default);
                const img = new Image();
                img.src = imagePath;

                await new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                });

                const imgCanvas = document.createElement('canvas');
                const imgCtx = imgCanvas.getContext('2d');
                const a4WidthInPixels = 210 * 3.78;
                imgCanvas.width = a4WidthInPixels;
                imgCanvas.height = (img.height * imgCanvas.width) / img.width;
                imgCtx.drawImage(img, 0, 0, imgCanvas.width, imgCanvas.height);

                const imgData = imgCanvas.toDataURL('image/png');
                pdf.addImage(imgData, 'PNG', 0, 0, imgCanvas.width / 3.78, imgCanvas.height / 3.78);
            } catch (error) {
                console.log('report 이미지를 찾을 수 없습니다.');
            }

            // 3페이지와 4페이지에 Report 컴포넌트 표시
            pdf.addPage();

            if (reportImgHeight > pageHeight) {
                const firstCanvas = document.createElement('canvas');
                const ctx1 = firstCanvas.getContext('2d');
                firstCanvas.width = reportCanvas.width;
                firstCanvas.height = splitPoint;

                // 첫 번째 페이지 - 하단 부분 처리
                ctx1.drawImage(
                    reportCanvas,
                    0,
                    0,
                    reportCanvas.width,
                    splitPoint - 30,  // 하단 여백
                    0,
                    0,
                    reportCanvas.width,
                    splitPoint - 30
                );

                // 두 번째 페이지 - 상단 부분 처리
                const secondCanvas = document.createElement('canvas');
                const ctx2 = secondCanvas.getContext('2d');
                secondCanvas.width = reportCanvas.width;
                secondCanvas.height = reportCanvas.height - splitPoint + 60;
                
                ctx2.drawImage(
                    reportCanvas,
                    0,
                    splitPoint - 30,  // 겹치는 부분 시작점
                    reportCanvas.width,
                    reportCanvas.height - splitPoint + 60,
                    0,
                    30,  // 상단 여백
                    reportCanvas.width,
                    reportCanvas.height - splitPoint
                );

                // PDF에 이미지 추가
                const firstImgData = firstCanvas.toDataURL('image/png');
                pdf.addImage(
                    firstImgData,
                    'PNG',
                    xOffset,
                    0,
                    reportImgWidth,
                    ((splitPoint - 30) * reportImgWidth) / reportCanvas.width
                );

                pdf.addPage();
                const secondImgData = secondCanvas.toDataURL('image/png');
                pdf.addImage(
                    secondImgData,
                    'PNG',
                    xOffset,
                    15, // 상단 여백
                    reportImgWidth,
                    ((reportCanvas.height - splitPoint) * reportImgWidth) / reportCanvas.width
                );

                console.log({
                    totalHeight: reportImgHeight,
                    splitPoint: splitPoint,
                    firstPartHeight: splitPoint,
                    secondPartHeight: reportCanvas.height - splitPoint
                });
            } else {
                // 짧은 내용일 경우 3페이지에 전체 내용 표시
                pdf.addImage(
                    reportImgData,
                    'PNG',
                    xOffset,
                    0,
                    reportImgWidth,
                    reportImgHeight
                );
            }

            // 4페이지: report2 이미지
            pdf.addPage();
            try {
                const imagePath = await import(`../../assets/report2/${item.sort_num}.png`).then(module => module.default);
                const img = new Image();
                img.src = imagePath;

                await new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                });

                const imgCanvas = document.createElement('canvas');
                const imgCtx = imgCanvas.getContext('2d');
                const a4WidthInPixels = 210 * 3.78;
                imgCanvas.width = a4WidthInPixels;
                imgCanvas.height = (img.height * imgCanvas.width) / img.width;
                imgCtx.drawImage(img, 0, 0, imgCanvas.width, imgCanvas.height);

                const imgData = imgCanvas.toDataURL('image/png');
                pdf.addImage(imgData, 'PNG', 0, 0, imgCanvas.width / 3.78, imgCanvas.height / 3.78);
            } catch (error) {
                console.log('report2 이미지를 찾을 수 없습니다.');
            }

            // 5이지: report3 이미지
            pdf.addPage();
            try {
                const imagePath = await import(`../../assets/report3/${item.sort_num}.png`).then(module => module.default);
                const img = new Image();
                img.src = imagePath;

                await new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                });

                const imgCanvas = document.createElement('canvas');
                const imgCtx = imgCanvas.getContext('2d');
                const a4WidthInPixels = 210 * 3.78;
                imgCanvas.width = a4WidthInPixels;
                imgCanvas.height = (img.height * imgCanvas.width) / img.width;
                imgCtx.drawImage(img, 0, 0, imgCanvas.width, imgCanvas.height);

                const imgData = imgCanvas.toDataURL('image/png');
                pdf.addImage(imgData, 'PNG', 0, 0, imgCanvas.width / 3.78, imgCanvas.height / 3.78);
            } catch (error) {
                console.log('report3 이미지를 찾을 수 없습니다.');
            }

            pdf.save(`${item.story_type_name}_report.pdf`);

            document.body.removeChild(tempDiv);

        } catch (error) {
            console.error('PDF 생성 중 오류:', error);
            alert('PDF 생성에 실패했습니다. 다시 시도해주세요.');
        }
    };

    return (
        <div className="forward_type my_list">
            {listData.map((item, index) => (
                <div className="assessment_info childrenDetail" key={`${item.goods_seq}-${index}`}>
                    <div className="assessment_details">
                        <div className="assessment_title ellipsis1">{item.goods_name}</div>
                        <div className="assessment_meta group_line" style={{ paddingTop: "6px" }}>
                            {item?.story_title_sub &&
                                <div className="assessment_code group_item">{item.story_title_sub}</div>}
                            {item?.inspection_cnt &&
                            <div className="assessment_code group_item">{item.inspection_cnt}회차</div>}
                        </div>
                        <button 
                            type="button" 
                            className="resend_button"
                            onClick={() => handleDownloadPDF(item)}
                        >
                            PDF 다운받기
                        </button>
                    </div>
                    <div className="children_image_container">
                        <img className="Children_Img" src={item.list_image} alt="Children_Img" />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default MyReportList;
