import React, { useState, useEffect } from "react";
import Button from "../../../components/Button/Button";
import Test_Img from "../../../assets/img/test_img2.png"; 
import { useNavigate, useLocation } from "react-router-dom";
import OutPop from "../../../components/Popup/OutPop";
import Header from "../../../components/Header/Header";
import QuestionList from '../../../components/QuestionTypes/QuestionComponent';
import instance from "../../../services/services";
import { SYNTHESIS_QUESTION_URL, SYNTHESIS_ANSWER_URL } from "../../../utils/urls";
import { getQuestionDescriptions } from '../../../data/mock/question';

const SynthesisTestDetail = () => { 
    const navigate = useNavigate();
    const location = useLocation();
    const [questions, setQuestions] = useState([]); // 문항 데이터
    const [tab, setTab] = useState('tab1');
    const [totalTabs, setTotalTabs] = useState(0); // 탭 개수 
    const [numberCheck, setNumberCheck] = useState({}); // 문제 체크 상태
    const [showImage, setShowImage] = useState(false); // 문항이 끝나고 이미지 표출
    const [showQuestions, setShowQuestions] = useState(true); // 문항 표시 여부
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);
    const [reportSeq, setReportSeq] = useState(null);
    const [goodsSeq, setGoodsSeq] = useState(null);

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await instance.post(SYNTHESIS_QUESTION_URL, {
                    order_seq: location.state.order_seq,
                    item_option_seq: location.state.item_option_seq,
                    goods_seq: location.state.goods_seq
                });

                if (response.data.code === -201) {
                    alert(response.data.message);
                    navigate('/');
                    return;
                }

                if (response.data.code === 200) {
                    setReportSeq(response.data.data.report_info.report_seq);
                    setGoodsSeq(response.data.data.report_info.goods_seq);
                    const questionData = response.data.data.questions || [];
                    setQuestions(questionData);
                    
                    const tabCount = Math.ceil(questionData.length / 10); // 탭에 10개의 문항 표출
                    setTotalTabs(tabCount);
                    
                    const initialNumberCheck = {};
                    for (let i = 1; i <= tabCount; i++) {
                        initialNumberCheck[`tab${i}`] = {};
                    }
                    setNumberCheck(initialNumberCheck);
                }
            } catch (error) {
                console.error("Error fetching questions:", error);
            }
        };

        fetchQuestions();
    }, [location.state, navigate]);

    const getQuestionType = (answerType) => {
        switch(answerType) {
            case "2": // 2지선다 
                return "binary";
            case "3": // 3지선다 
                return "threePoint";
            case "5": // 5지선다 
                return "fivePoint";
            default:
                return "fivePoint";
        }
    };

    const getCurrentQuestions = () => {
        if (!questions.length) return { groupDescription: null, questions: [] };
        
        const tabNumber = parseInt(tab.replace('tab', ''));
        const startIndex = (tabNumber - 1) * 10;
        const endIndex = startIndex + 10;
        
        const currentQuestions = questions.slice(startIndex, endIndex);
        const descriptions = getQuestionDescriptions(location.state.goods_seq || "18");
        
        // 현재 탭의 첫 번째 문항의 sort_num
        const firstQuestionSortNum = parseInt(currentQuestions[0]?.sort_num) || 0;
        // const lastQuestionSortNum = parseInt(currentQuestions[currentQuestions.length - 1]?.sort_num) || 0;
        
        // 현재 탭에 적용될 설명 찾기
        let currentDescription = null;
        
        // 현재 탭의 문항 범위에 해당하는 가장 최근의 설명 찾기
        const applicableDescription = descriptions
            .filter(desc => desc.displayBeforeQuestion <= firstQuestionSortNum)
            .sort((a, b) => b.displayBeforeQuestion - a.displayBeforeQuestion)[0];

        if (applicableDescription) {
            currentDescription = applicableDescription.content;
        }

        // 현재 탭의 문항들 처리
        const questionsWithDescriptions = currentQuestions.map(question => {
            const sortNum = parseInt(question.sort_num);
            // 해당 문항이 새로운 설명의 시작점인 경우에만 specificDescription 추가
            const specificDescription = descriptions.find(desc => 
                desc.displayBeforeQuestion === sortNum && 
                // 탭 상단의 설명과 다른 경우에만 표시
                desc.content !== currentDescription
            )?.content;
            
            return {
                ...question,
                specificDescription,
                count: `Q${question.sort_num}`,
                content: question.qst_content,
                qst_seq: question.qst_seq,
                type: question.answer_type
            };
        });

        return {
            groupDescription: currentDescription,
            questions: questionsWithDescriptions
        };
    };

    const handleNumberCheck = (questionIndex, selectedCheck, currentTab) => {
        setNumberCheck(prev => ({
            ...prev,
            [tab]: {
                ...prev[tab],
                [questionIndex]: selectedCheck
            }
        }));
    };

    const allAnswered = (tabAnswers) => {
        if (!tabAnswers) return false;
        const currentQuestions = getCurrentQuestions().questions;
        const answeredCount = Object.keys(tabAnswers).length;
        return answeredCount === currentQuestions.length;
    };

    const handleButtonClick = async () => {
        if (showQuestions) {
            if (allAnswered(numberCheck[tab])) {
                try {
                    // 현재 탭의 문제들 가져오기
                    const tabNumber = parseInt(tab.replace('tab', ''));
                    const startIndex = (tabNumber - 1) * 10;
                    const endIndex = startIndex + 10;
                    const currentTabQuestions = questions.slice(startIndex, endIndex);
                    
                    // 답변 데이터 구조화
                    const answers = currentTabQuestions.map(question => {
                        return {
                            "qst_seq": parseInt(question.qst_seq),
                            "choice_val": parseInt(numberCheck[tab][question.sort_num]),
                            "short_answer": ""
                        };
                    });

                    // 서버에 전송할 데이터 구조화
                    const requestBody = {
                        "report_seq": parseInt(reportSeq),
                        "answers": answers
                    };

                    // 디버깅용 로그
                    console.log('Sending request:', JSON.stringify(requestBody, null, 2));

                    const response = await instance.post(SYNTHESIS_ANSWER_URL, requestBody, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    
                    if (response.data.code === 200) {
                        if (tab === `tab${totalTabs}`) {
                            navigate('/synthesiscomplete', { state: { goods_seq: goodsSeq } });
                        } else {
                            setShowQuestions(false);
                            setShowImage(true);
                        }
                    } else {
                        throw new Error(response.data.message || '서버 응답 오류');
                    }
                } catch (error) {
                    console.error("Error details:", {
                        message: error.message,
                        response: error.response?.data,
                        status: error.response?.status,
                        statusText: error.response?.statusText,
                        requestBody: error.config?.data
                    });
                    alert('답변 저장 중 오류가 발생했습니다. 다시 시도해주세요.');
                }
            }
        } else {
            setShowImage(false);
            setTab(`tab${parseInt(tab.replace('tab', '')) + 1}`);
            setShowQuestions(true);
        }
    };

    const handleModalYesClick = () => {
        navigate("/");
    };

    const handleBackClick = () => {
        setIsOutPopOpen(true);
    };

    return (
        <div className="generalTestDetail">
            <div className="content">
                <Header title="종합 검사" handleBack={handleBackClick}/>
                <div className="content_body">
                    <div style={{ display: "flex", gap: "4px" }}>
                        {[...Array(totalTabs)].map((_, index) => (
                            <div
                                key={`tab${index + 1}`}
                                className={`tab ${tab === `tab${index + 1}` ? "activeTab" : ""}`}
                                onClick={() => setTab(`tab${index + 1}`)}
                            />
                        ))}
                    </div>

                    <div className="tabContent">
                        <div>
                            {showQuestions && (
                                <div>
                                    {/* 탭 상단의 그룹 설명 */}
                                    {getCurrentQuestions().groupDescription && (
                                        <div className="question-description group-description">
                                            {getCurrentQuestions().groupDescription}
                                        </div>
                                    )}
                                    {/* 개별 문항과 해당하는 설명 */}
                                    {getCurrentQuestions().questions?.map((question, index) => (
                                        <div key={question.qst_seq}>
                                            {question.specificDescription && (
                                                <div className="question-description specific-description">
                                                    {question.specificDescription}
                                                </div>
                                            )}
                                            <QuestionList
                                                questions={[question]}
                                                selectedCheck={numberCheck[tab]?.[question.sort_num]}
                                                onNumberCheck={(_, check) => handleNumberCheck(question.sort_num, check, tab)}
                                                questionType={getQuestionType(question.type)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            {showImage && (
                                <div className="image-overlay">
                                    <img className="Test_Img" src={Test_Img} alt="설명" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="Edit_btn_change">
                    <Button 
                        label="다음" 
                        variant="btn_next" 
                        className={showImage || (showQuestions && allAnswered(numberCheck[tab])) ? "active" : "disabled"} 
                        onClick={handleButtonClick} 
                    />
                </div>
                <OutPop
                    isOpen={isOutPopOpen}
                    onClose={() => setIsOutPopOpen(false)}
                    onYesClick={handleModalYesClick} 
                />
            </div>
        </div>
    );
};

export default SynthesisTestDetail;
