import React, { useRef, useEffect, useState, useMemo } from "react";
import '../../styles/pages/report.css';
import Chart from 'chart.js/auto';
import instance from "../../services/services";
import { INSPECTION_REPORT_URL } from "../../utils/urls";
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

function Report({ reportData }) {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [reportResult, setReportResult] = useState(null);

    useEffect(() => {
        const fetchReportData = async () => {
            try {
                const response = await instance.post(INSPECTION_REPORT_URL, {
                    report_seq: reportData.report_seq
                });
                
                if (response.data.code === 200) {
                    console.log("리포트 데이터 전체:", response.data);
                    console.log("inspection_result 데이터:", response.data.data?.inspection_result);
                    setReportResult(response.data.data);
                }
            } catch (error) {
                console.error("리포트 데이터 조회 중 에러 발생:", error);
            }
        };

        if (reportData?.report_seq) {
            fetchReportData();
        } else {
            console.log("report_seq가 없습니다:", reportData);
        }
    }, [reportData]);

    // 차트 데이터를 useMemo로 감싸기
    const data = useMemo(() => {
        // inspection_result가 객체일 경우 배열로 변환
        const inspectionResults = reportResult?.inspection_result
            ? Object.values(reportResult.inspection_result)
            : [];

        return {
            labels: inspectionResults.map(item => item.area_name) || [],
            datasets: [
                {
                    data: inspectionResults.map(item => item.score_percent) || [],
                    backgroundColor: inspectionResults.map((_, index) => {
                        const colors = ["#FF5272", "#1FBCAA", "#FFD444", "#6093FF", "#AC75FF", "#000000"];
                        return colors[index];
                    }),
                    borderWidth: 0,
                    borderRadius: Number.MAX_VALUE,
                },
            ],
        };
    }, [reportResult]);

    // customPlugin을 useMemo로 감싸기
    const customPlugin = useMemo(() => ({
        id: 'moveBarsToFront',
        afterDatasetsDraw(chart) {
            chart.data.datasets.forEach((dataset, index) => {
                if (chart.isDatasetVisible(index)) {
                    const meta = chart.getDatasetMeta(index);
                    if (meta && meta.dataset && typeof meta.dataset.draw === 'function') {
                        meta.dataset.draw(chart.ctx);
                    }
                    meta.data.forEach(element => {
                        if (element && typeof element.draw === 'function') {
                            element.draw(chart.ctx);
                        }
                    });
                }
            });
        }
    }), []); // 의존성 배열이 비어있음 - 플러그인은 변경되지 않음

    // 차트 설정을 useEffect 안으로 이동
    useEffect(() => {
        if (!chartRef.current || !reportResult) return;

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const ctx = chartRef.current.getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
        gradient.addColorStop(0, 'rgba(255,255,255,1)');
        gradient.addColorStop(1, 'rgba(230,245,252,1)');

        const config = {
            type: "bar",
            data: data,
            options: {
                devicePixelRatio: 2,
                indexAxis: "y",
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: { display: false },
                    title: {
                        display: false,
                        text: "유 · 아동 교조증 행동 검사 결과",
                    },
                    annotation: {
                        annotations: [{
                            type: 'box',
                            xMin: 0,
                            xMax: 100,
                            backgroundColor: gradient,
                            borderWidth: 0,
                            label: {
                                content: '',
                                enabled: true,
                                position: 'center',
                            }
                        }],
                    }
                },
                scales: {
                    x: {
                        min: 0,
                        max: 100,
                        stepSize: 10,
                        title: { display: false },
                        ticks: {
                            stepSize: 10,
                            beginAtZero: true,
                        },
                    },
                    y: { display: false },
                },
            },
            plugins: [customPlugin]
        };

        chartInstance.current = new Chart(ctx, config);

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [reportResult, data, customPlugin]);

    const reportLegendStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };    
    return (
        <div className="view-report">
            <div className="report-content">
                <div className="report-wrapper rw01">
                    <div className="report-section chart-section">
                        <div className="report-title">
                            <div className="title-icon1"></div>동화 결과<div className="title-icon2"></div>
                        </div>
                        <div className="report-result">
                            <div className="report-result-title">
                                {reportResult?.inspection_info?.inspection_title || "검사 결과"}
                            </div>
                            <div className="report-graph">
                                <canvas ref={chartRef} className="chart-canvas" width="400" height="120"></canvas>
                            </div>
                            <div className="report-legend" style={reportLegendStyle}>
                                {reportResult?.inspection_result 
                                    ? Object.values(reportResult.inspection_result).map((item, index) => (
                                        <div key={index} className="legend-item">
                                            <div
                                                className="legend-color-box"
                                                style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
                                            ></div>
                                            <div className="legend-label">{item.area_name}</div>
                                        </div>
                                    ))
                                    : null
                                }
                            </div>
                        </div>
                        <div className="report-class">
                            {reportResult?.inspection_range?.map((range, index) => (
                                <span key={index}>
                                    {range.per_min}~{range.per_max} {range.inspection_status}
                                    {index < reportResult.inspection_range.length - 1 ? ' / ' : ''}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="report-wrapper rw02">
                    <div className="report-section table-section">
                        <div className="report-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th> </th>
                                        <th>원점수</th>
                                        <th>백분율(%)</th>
                                        <th>수준</th>
                                        <th>종합 해설</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportResult?.inspection_result 
                                        ? Object.values(reportResult.inspection_result).map((result, index) => (
                                            <tr key={index}>
                                                <td>{result.area_name}</td>
                                                <td>{result.total_score}</td>
                                                <td>{result.score_percent}</td>
                                                <td>{result.inspection_status}</td>
                                                <td>
                                                    {result.summary_detail}
                                                </td>
                                            </tr>
                                        ))
                                        : null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="report-wrapper rw03">

                </div>
            </div>
        </div>
    )
}
export default Report;