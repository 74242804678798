import React, {useCallback, useEffect, useRef, useState} from "react";
import Button from "../../components/Button/Button";
import {useLocation, useNavigate} from "react-router-dom";
import Header from "../../components/Header/Header";
import {checkUserEmail, checkUserId, checkUserNickname, joinUser} from "../../services/Join/Join";
import {ERROR_MESSAGE} from "../../utils/constants";
import {emailRegex, numericRegex, validateInput} from "../../utils/validation";
import {CERTIFICATION, CERTIFICATION_CHECK} from "../../utils/urls";
import instance from "../../services/services";
import useSnsJoin from "../../hooks/login/snsJoin";
import { passwordvisible } from "../../utils/passwordvisible";

function UserInfoForm() {
    const errorMapping = {
        ID_EXISTENCT: false,
        PASSWORD: false,
        PASSWORD_NOMATCH: false,
        EMAIL_EXISTENCT: false,
        EMAIL_FORMAT: false,
        NICKNAME_EXISTENCT: false,
        NICKNAME_EDIT: false,
        ID: false,
    }
    const navigate = useNavigate();
    const location = useLocation();
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [email, setEmail] = useState('');
    const [nickname, setNickname] = useState('');
    const [idToken, setIdToken] = useState('');
    const [phonenumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');
    const [isSnsJoin, setIsSnsJoin] = useState(false);
    const [error, setError] = useState(errorMapping)
    const [isSuccess, setIsSuccess] = useState(false)
    const [certification, setCertification] = useState('');
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [isTimeOver, setIsTimeOver] = useState(false);
    const [timer, setTimer] = useState(120); // 2분
    const [certificationMsg, setCertificationMsg] = useState('');
    const [isCertification, setIsCertification] = useState(false);// 인증 성공 유무
    const [snsProvider, setSnsProvider] = useState('');
    const [isShowPw1, setShowPw1] = useState(false);
    const [isShowPw2, setShowPw2] = useState(false);
    const {createSnsUser} = useSnsJoin(snsProvider, {email, nickname, phonenumber, name, idToken});
    const isInitialRender = useRef(true);//sns 가입 첫 로드시 실행
    const handleBackClick = () => {
        window.history.back();
    };

    // 인증번호
    useEffect(() => {
        let interval = null;
        if(isCertification){
            clearInterval(interval);
            setIsResendDisabled(false);
        }else if (isResendDisabled && timer > 0) {
            interval = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
        } else if (timer === 0) {
            setIsResendDisabled(false);
            setTimer(120);
            setIsTimeOver(true)
            setCertificationMsg('')

        }
        return () => clearInterval(interval);
    }, [isResendDisabled, timer,isCertification]);


    // 인증 번호 담기
    const handleCertificationChange = async (e) => {
        const certificationNum = e.target.value;

        if (isTimeOver) {
            return setCertification('');
        }

        setCertification(certificationNum);

        if (certificationNum.length === 6) {
            await checkCertification(certificationNum); 
        }

    };

    useEffect(() => {
        if (isTimeOver) {
            setCertification('');
        }
    }, [isTimeOver]);
    // input 입력
    const handleInputChange = (setter) => (e) => {
        // 핸드폰 숫자만 입력 가능
        if (e.target.name === 'user_phone') {
            const numericValue = numericRegex(e.target.value);
            return setter(numericValue)
        }
        // name에 따라 input 포커스 되면 에러메세지 제거
        setError((prev) => ({
            ...prev,
            ...(e.target.name === 'user_id' ? {ID_EXISTENCT: false} : {EMAIL_EXISTENCT: false})
        }));
        setter(e.target.value)
    };


    //1step: focus out 실행
    const handleBlur = (type) => {
        CheckDuplicate(type);
    };

    //2step: focus out 중복 체크
    const CheckDuplicate = useCallback(async (type) => {
        // 이메일에 경우 이메일 형식 먼저 체크할 것
        if (type === 'email') {
            setError((prev) => ({
                ...prev,
                EMAIL_FORMAT: !emailRegex(email) // 이메일 형식이 맞지 않을 경우 true
            }));
        }

        if (type === 'nickname') {
            setError((prev) => ({
                ...prev,
                NICKNAME_EDIT: nickname.length < 2 || nickname.length > 10 // 닉네임 길이가 맞지 않으면 true
            }));
        }


        if (type === 'id' && !isSnsJoin) {
            setError((prev) => ({
                ...prev,
                ID: !validateInput(id) || (id.length < 4 || nickname.length > 16)
            }));
        }

        // 아이디 이메일 닉네임 중복 체크

        const typeMapping = {
            id: () => checkUserId({userid: id}),
            email: () => checkUserEmail({email: email}),
            nickname: () => checkUserNickname({nickname: nickname})
        };

        //중복체크 이후 에러 메세지 매핑
        const checkMapping = {
            id: (code) => (code === 200 ? {ID_EXISTENCT: true} : {ID_EXISTENCT: false}),
            email: (code) => (code === -200 ? {EMAIL_EXISTENCT: true} : {EMAIL_EXISTENCT: false}),
            nickname: (code) => (code === -200 ? {NICKNAME_EXISTENCE: true} : {NICKNAME_EXISTENCE: false})
        };

        try {
            const response = await typeMapping[type]();
            setError((prev) => ({
                ...prev,
                ...(checkMapping[type] ? checkMapping[type](response.data.code) : {})
            }));
        } catch (e) {
            console.error(e);
        }

    }, [email, nickname, id, isSnsJoin])


    const checkPwUsage = () => {
        if (password.length < 6 || password.length > 15) {
            return setError((prev) => ({
                ...prev,
                PASSWORD: true
            }));
        }
        if (!validateInput(password, true)) {
            return setError((prev) => ({
                ...prev,
                PASSWORD: true
            }));
        }

        return setError(errorMapping)
    }

    const checkPwConfirm = () => {
        if (passwordCheck && passwordCheck !== password) {
            return setError((prev) => ({
                ...prev,
                PASSWORD_NOMATCH: true
            }));
        }
        if (passwordCheck && validateInput(password, true)) {
            setIsSuccess(true)
            return setError(errorMapping)
        }
    }
    const snsValidations = {
        isValidEmail: email.trim().length > 0,
        isValidNickname: nickname.trim().length > 0,
        isValidPhoneNumber: phonenumber.trim().length > 0,
        isValidName: name.trim().length > 0,
        isValidCertification: certification.trim().length === 6,
    };

    const normalValidations = {
        isValidId: id.trim().length > 0,
        isValidPassword: password.trim().length > 0,
        isValidPasswordCheck: passwordCheck.trim().length > 0,
        isValidCertification: certification.trim().length === 6,
        ...snsValidations
    };

    const isErrorFree = Object.values(error).every(value => !value);

    const isAllValid = isErrorFree && (isSnsJoin
        ? Object.values(snsValidations).every(valid => valid)
        : Object.values(normalValidations).every(valid => valid))

    const checkCertification = async (certificationNum) => {
        try {
            // 1step:: 인증번호 체크
            const certificationRes = await instance.post(CERTIFICATION_CHECK, {
                cert_code: certificationNum,
                phoneNumber: phonenumber
            });

            if (certificationRes.data.code === 200) {
                setCertificationMsg('인증되었습니다.');
                setIsCertification(true)
            } else {
                setCertificationMsg(certificationRes.data.message);
                setIsCertification(false)
            }

        } catch (error) {
            console.error(error.response?.data?.message);
        }
    };


    //인증 코드 보내기
    const handleSendCode = async () => {
        if (id === '' && !isSnsJoin) {
            setCertificationMsg('아이디를 먼저 입력하세요.');
            return
        }
        try {
            setIsTimeOver(false)
            const response = await instance.post(CERTIFICATION, {
                phoneNumber: phonenumber
            });

            if (response.data.code === 200) {
                // 임시로 인증번호 전송 성공 처리
                setIsResendDisabled(true);
                setIsCertification(false)
                setCertificationMsg('');
                setTimer(120);

            } else {

                setCertificationMsg(response.data.message);
            }


        } catch (error) {
            console.error('인증번호 발송 실패:', error);
            alert("인증번호 발송 중 오류가 발생했습니다.");
        }
    };

    //가입
    const handleNextClick = async () => {
        if (!isCertification) return
        if (snsProvider && isSnsJoin) {
            return await createSnsUser()
        }
        try {
            const joinData = {
                userid: isSnsJoin ? location.state?.snsUserInfo?.id : id,
                email: email,
                password: isSnsJoin ? "" : password,  // SNS 회원가입이 아닐 경우에만 비밀번호 전송
                user_name: name,
                nickname: nickname,
                mem_phone: phonenumber,
                push: "Y",
                push_id: "",
                ci: "",
                di: "",
                sns_type: isSnsJoin ? location.state?.provider : "",
                sns_token: isSnsJoin ? location.state?.snsUserInfo?.token : ""
            };

            const response = await joinUser(joinData);

            if (response?.data?.code === 200) {
                const userInfo = {
                    user_name: name,
                    userid: isSnsJoin ? email : id,
                    nickname: nickname,
                    email: email,
                    mem_phone: phonenumber,
                    is_sns: isSnsJoin ? "Y" : "N"
                };

                localStorage.setItem('userEmail', email);
                localStorage.setItem('userInfo', JSON.stringify(userInfo));

                navigate('/joinsuccess', {state: {userName: name}});
            } else {
                navigate('/joinfail');
            }
        } catch (error) {
            console.error('회원가입 실패:', error);
            navigate('/joinfail');
        }
    };

    useEffect(() => {
        const checkSnsJoin = () => {
            const fromSns = location.state?.fromSns || false;
            const snsUserInfo = location.state?.snsUserInfo || {};
            const snsProvider = location.state?.provider || '';

            setIsSnsJoin(fromSns);
            setSnsProvider(snsProvider)
            if (fromSns && snsUserInfo) {

                setId(snsUserInfo.email || '');
                setEmail(snsUserInfo.email || '');
                setName(snsUserInfo.name || '');
                setNickname(snsUserInfo.nickname || '');
                setIdToken(snsUserInfo.id_token || '');

            }
        };

        checkSnsJoin();
    }, [location]);


    useEffect(() => {

        if (isSnsJoin && isInitialRender.current) {
            isInitialRender.current = false
            CheckDuplicate('email');
            CheckDuplicate('nickname');
        }
    }, [isSnsJoin, CheckDuplicate]);
    return (
        <div className="idfind">
            <div className="content">
                <Header title="정보입력" handleBack={handleBackClick}/>
                <div className="content_body">
                    <div className="nickname_instruction">회원가입을 위해<br/>기본정보를 입력해 주세요</div>

                    {!isSnsJoin && (
                        <>
                            <div className="nickname_input_container default_input">
                                <div className="nickname_label label">아이디</div>
                                <input
                                    type="text"
                                    className={`login_input ${error.ID_EXISTENCT ? 'error' : ''}`}
                                    value={id}
                                    name="user_id"
                                    onChange={handleInputChange(setId)}
                                    onBlur={() => handleBlur('id')}
                                />
                            </div>
                            {(error.ID_EXISTENCT || error.ID) &&
                                <div
                                    className={`error_message nickname`}>{error.ID_EXISTENCT ? ERROR_MESSAGE.ID_EXISTENCT : ERROR_MESSAGE.ID}</div>}
                            <div className="nickname_input_container default_input">
                                <div className="nickname_label label">비밀번호</div>
                                <div className="comb_input">
                                    <input
                                        type={isShowPw1 ? 'text' : 'password'}
                                        className="login_input"
                                        value={password}
                                        onChange={handleInputChange(setPassword)}
                                        onBlur={() => {
                                            checkPwUsage();
                                            checkPwConfirm();
                                        }}
                                        placeholder="6~15자의 영문/숫자/특수문자 조합"
                                    />
                                    <button 
                                        type="button" 
                                        className={`input_btn show_pw_btn ${isShowPw1 ? 'show' : ''}`}
                                        onClick={() => setShowPw1(!isShowPw1)}
                                        onMouseDown={(e) => passwordvisible(e, password)}
                                    ></button>
                                </div>

                                {error.PASSWORD &&
                                    <div className={`error_message nickname`}>{ERROR_MESSAGE.PASSWORD}</div>}
                                <div className="comb_input">
                                    <input
                                        style={{marginTop: '8px'}}
                                        type={isShowPw2 ? 'text' : 'password'}
                                        className="login_input"
                                        value={passwordCheck}
                                        onBlur={checkPwConfirm}
                                        onChange={handleInputChange(setPasswordCheck)}
                                        placeholder="비밀번호 재입력"
                                    />
                                    <button 
                                        type="button" 
                                        className={`input_btn show_pw_btn ${isShowPw2 ? 'show' : ''}`}
                                        onClick={() => setShowPw2(!isShowPw2)}
                                        onMouseDown={(e) => passwordvisible(e, passwordCheck)}
                                        style={{marginTop:'4px'}}
                                    ></button>
                                </div>
                            </div>
                            {(error.PASSWORD_NOMATCH || isSuccess) && (
                                <div className="error_message nickname">
                                    {error.PASSWORD_NOMATCH ? ERROR_MESSAGE.PASSWORD_NOMATCH : '사용 가능한 비밀번호 입니다.'}
                                </div>
                            )}

                        </>
                    )}

                    <div className="nickname_input_container default_input">
                        <div className="nickname_label label">이메일</div>
                        <input
                            type="text"
                            className="login_input"
                            value={email}
                            name="user_email"
                            onChange={handleInputChange(setEmail)}
                            onBlur={() => handleBlur('email')}
                            placeholder="이메일 입력"
                        />
                    </div>
                    {(error.EMAIL_EXISTENCT || error.EMAIL_FORMAT) &&
                        <div
                            className={`error_message nickname`}>{error.EMAIL_EXISTENCT ? ERROR_MESSAGE.EMAIL_EXISTENCT : ERROR_MESSAGE.EMAIL_FORMAT}</div>}
                    <div className="nickname_input_container default_input">
                        <div className="nickname_label label">닉네임</div>
                        <input
                            type="text"
                            className="login_input"
                            value={nickname}
                            maxLength={10}

                            name="user_nickname"
                            onChange={handleInputChange(setNickname)}
                            onBlur={() => handleBlur('nickname')}
                            placeholder="닉네임 입력"
                        />
                    </div>
                    {(error.NICKNAME_EDIT || error.NICKNAME_EXISTENCE) &&
                        <div
                            className={`error_message nickname`}>{error.NICKNAME_EDIT ? ERROR_MESSAGE.NICKNAME_EDIT : ERROR_MESSAGE.NICKNAME_EXISTENCE}</div>}

                    <div className="nickname_input_container default_input">
                        <div className="nickname_label label">휴대폰 번호</div>

                        <div style={{position: 'relative'}}>
                            <input
                                type="text"
                                className="login_input"
                                value={phonenumber}
                                name="user_phone"
                                onChange={handleInputChange(setPhoneNumber)}
                                placeholder="휴대폰 번호 입력"
                            />
                            <button
                                className="resend_button"
                                onClick={handleSendCode}
                                disabled={isResendDisabled || !phonenumber.trim()}
                                style={{position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)'}}
                            >
                                인증번호 전송
                            </button>
                        </div>

                        <div style={{position: 'relative'}}>
                            <input
                                style={{marginTop: "8px"}}
                                type="text"
                                className="nickname_input"
                                maxLength={6}
                                value={certification}
                                onChange={handleCertificationChange}
                                placeholder="인증번호 입력"
                            />
                            {isResendDisabled && (
                                <div className="resend_time">
                                    <span>
                                        {Math.floor(timer / 60)}분 {String(timer % 60).padStart(2, '0')}초
                                    </span>
                                </div>
                            )}
                            {isTimeOver && (
                                <div className="resend_time">
                                    <span>
                                        인증번호 시간이 만료되었습니다.
                                    </span>
                                </div>
                            )}
                            {
                                certificationMsg && <div className="resend_time">
                                    <span>
                                        {certificationMsg}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="nickname_input_container default_input">
                        <div className="nickname_label label">이름</div>
                        <input
                            type="text"
                            className="login_input"
                            value={name}
                            onChange={handleInputChange(setName)}
                            placeholder="이름 입력"
                        />
                    </div>
                </div>

                <div className="Edit_btn_change">
                    <Button
                        label="다음"
                        variant="btn_next"
                        onClick={handleNextClick}
                        disabled={!isAllValid}
                        className={isAllValid ? 'active' : 'disabled'}
                    />
                </div>
            </div>
        </div>
    );
}

export default UserInfoForm;
