import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import Badge from "../../components/Badge/Badge";
import Button from "../../components/Button/Button";
import ShoppingPop from "../../components/Popup/ShoppingPop";
import { instance } from "../../services/services";
import { PACKAGE_TEST_URL, TOSS_PAYMENT_URL, CART_URL } from "../../utils/urls";
import { loadTossPayments } from '@tosspayments/payment-sdk';
import useUserStore from '../../store/user/userStore';
import { formatLocalString } from "../../utils/validation";

function SeriesPayment() {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    
    const goodsSeq = location.state?.goodsSeq || searchParams.get('goods_seq');
    const packageCnt = location.state?.packageCnt;
    const category = location.state?.category;
    
    const [packageData, setPackageData] = useState(null);
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);
    const callArrowContainerRef = useRef(null);
    const { memberTokens } = useUserStore();

    useEffect(() => {
        const fetchPackageData = async () => {
            try {
                if (!goodsSeq) {
                    console.error('상품 정보가 없습니다');
                    return;
                }

                const response = await instance.post(PACKAGE_TEST_URL, {
                    goods_seq: goodsSeq
                });

                if (response.data.code === 200) {
                    setPackageData(response.data.data);
                }
            } catch (error) {
                console.error('패키지 데이터 조회 오류:', error);
            }
        };

        fetchPackageData();
    }, [goodsSeq]);

    const handleShoppingClick = async () => {
        try {
            if (!memberTokens) {
                console.error('토큰이 없습니다');
                navigate('/login');
                return;
            }

            // packageData가 null이 아닌지 확인
            if (!packageData || !packageData.goodsInfo) {
                console.error('상품 정보가 없습니다');
                return;
            }

            // packageData.goodsInfo.goods_seq 값을 사용하여 장바구니에 담기
            const cartData = {
                goods_seq: String(packageData.goodsInfo.goods_seq), // goodsInfo의 goods_seq
                ea: "1" // 수량은 항상 1
            };

            // 장바구니에 담을 데이터 전송
            const response = await instance.post(CART_URL, cartData, {
                headers: {
                    'Authorization': `Bearer ${memberTokens}` // 헤더에 토큰값 포함시켜야함
                }
            });

            if (response.data.code === 200) {
                setIsOutPopOpen(true); // 팝업창 열기
            } else {
                console.error('장바구니 담기 실패:', response.data.message);
            }
        } catch (error) {
            console.error('장바구니 API 호출 오류:', error);
        }
    };

    const handleBackClick = () => {
        navigate('/storytestpage');
    };

    const handleMouseDown = (e) => {
        const container = callArrowContainerRef.current;
        const startX = e.pageX - container.offsetLeft;
        const scrollLeft = container.scrollLeft;

        const handleMouseMove = (e) => {
            const x = e.pageX - container.offsetLeft;
            const walk = (x - startX) * 2;
            container.scrollLeft = scrollLeft - walk;
        };

        const handleMouseUp = () => {
            container.removeEventListener('mousemove', handleMouseMove);
            container.removeEventListener('mouseup', handleMouseUp);
            container.style.cursor = 'grab';
        };

        container.addEventListener('mousemove', handleMouseMove);
        container.addEventListener('mouseup', handleMouseUp);
        container.style.cursor = 'grabbing'; 
    };

    const handleDirectPurchase = async () => {
        try {
            const storedToken = localStorage.getItem('accessToken') || memberTokens;
            
            if (!storedToken || !packageData?.goodsInfo) {
                console.error('토큰 또는 상품 정보가 없습니다');
                navigate('/login');
                return;
            }

            const paymentRequestBody = {
                member_seq: "4",
                settlePrice: Number(packageData.goodsInfo.price),
                order_kind: "story",
                use_point: null,
                mode: "choice",
                payment: "card",
                items: [
                    {
                        goods_seq: packageData.goodsInfo.goods_seq,
                        option_seq: "",
                        ea: "1"
                    }
                ],
                validation_data: {
                    goods_name: packageData.goodsInfo.goods_name,
                    inspection_title: category,
                    original_price: packageData.goodsInfo.price
                }
            };

            const response = await instance.post(TOSS_PAYMENT_URL, paymentRequestBody);
            
            if (response.data.code === 200) {
                const paymentInfo = response.data.data.data;
                const clientKey = process.env.NODE_ENV === 'development' 
                    ? 'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq'
                    : process.env.REACT_APP_TOSS_CLIENT_KEY;

                const tossPayments = await loadTossPayments(clientKey);
                await tossPayments.requestPayment('카드', {
                    amount: paymentInfo.amount,
                    orderId: paymentInfo.orderId,
                    orderName: paymentInfo.orderName,
                    customerName: paymentInfo.customerName,
                    successUrl: `${window.location.origin}/payment/result?goods_seq=${packageData.goodsInfo.goods_seq}&order_kind=story&redirect_path=/my-list&redirect_params=type=test`,
                    failUrl: `${window.location.origin}/payment/result?fail_redirect_path=/seriespayment&fail_redirect_params=goods_seq=${packageData.goodsInfo.goods_seq}`
                });
            } else {
                throw new Error(response.data.message || '결제 요청이 실패했습니다.');
            }
        } catch (error) {
            console.error('결제 처리 중 에러:', error);
            alert('결제 처리 중 오류가 발생했습니다. 다시 시도해주세요.');
            navigate(`/seriespayment?goods_seq=${packageData.goodsInfo.goods_seq}`);
        }
    };

    return (
        <div className="CallPayment">
            <div className="content">
                <Header title="동화 검사" handleBack={handleBackClick} />
                <div className="info_wrapper">
                    <Badge badgeColor="primary-60" badgeText="동화" />
                    <div className="intro_payment_group">{packageData?.goodsInfo?.goods_name} 시리즈</div>
                </div>

                <div className="call_payment_wrapper">
                    <div className="call_type_container">
                        <div className="call_type_info">
                            <div className="call_type_title">{packageData?.goodsInfo?.goods_name}</div>
                            <div className="payment_type_details">
                                <div>
                                    {category}
                                    <span className="vertical-bar" style={{ background: "#B4B5B9" }}></span>
                                    <span>{packageCnt || packageData?.packageGoods?.length || 0}권</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="call_img_data"
                        style={{paddingBottom:"20px"}}
                        onMouseDown={handleMouseDown}
                        ref={callArrowContainerRef}
                    >
                        {packageData?.packageGoods?.map((item, index) => (
                            <div 
                                key={index}
                                onClick={() => navigate('/seriesSinglepayment', {
                                    state: { goodsSeq: item.goods_seq }
                                })}
                                style={{ cursor: 'pointer' }}
                            >
                                <img 
                                    src={item.mainImage} 
                                    alt={item.goods_name} 
                                    className="Call_img"
                                    style={{borderRadius: "32px"}}
                                />
                            </div>
                        ))}
                    </div>
                    <div style={{padding: "0px 24px"}}>
                        <div className="text_line payment"/>
                    </div>
                    <div className="price-info">
                        <div className="price-label">가격정보</div>
                        <div className="price-value">
                            {packageData?.goodsInfo.price ? `${formatLocalString(packageData?.goodsInfo.price)}원` : '0원'}
                        </div>
                    </div>
                </div>

                <div />
            </div>
            <div className="Edit_btn_complete">
                <Button  
                    label="장바구니 담기" 
                    variant="btn_join" 
                    onClick={handleShoppingClick}
                />
                <Button  
                    label="바로 구매하기" 
                    variant="btn_complete" 
                    onClick={handleDirectPurchase}
                    disabled={!packageData?.goodsInfo}
                />
            </div>
            <ShoppingPop
                isOpen={isOutPopOpen}
                onClose={() => setIsOutPopOpen(false)}
            />
        </div>
    );
}

export default SeriesPayment;
