import React, {useEffect} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import './styles/index.css';
import 'react-toastify/dist/ReactToastify.css';
import Main from './pages/Main/Main';
import Assimilation from './pages/Assimilation/Assimilation';
import MindPrescription from './pages/MindPrescription/MindPrescription';
import Alert from './pages/Alert/Alert';
import Edit from './pages/Information/Edit';
import NicknameEdit from './pages/Information/NicknameEdit';
import PasswordChange from './pages/Information/PasswordChange';
import PasswordSuccess from './pages/Information/PasswordSuccess';
import Login from './pages/Login/Login';
import IdFind from './pages/Login/IdFind';
import PasswordFind from './pages/Login/PasswordFind';
import Join from './pages/Join/Join';
import Agreement from './pages/Join/Agreement';
import UserInfoForm from './pages/Join/UserInfoForm';
import JoinSuccess from './pages/Join/JoinSuccess';
import JoinFail from './pages/Join/JoinFail';
import Intro from './pages/Mypage/Intro';
import GeneralTest from './pages/Examination/GeneralTest/GeneralTest';
import ChildrenPayment from './pages/Examination/GeneralTest/ChildrenPayment';
import ChildrenInformation from './pages/Examination/GeneralTest/ChildrenInformation';
import GeneralTestDetail from './pages/Examination/GeneralTest/GeneralTestDetail';
import TestComplete from './pages/Examination/GeneralTest/TestComplete';
import SynthesisPayment from './pages/Examination/Synthesis/SynthesisPayment';
import SingleTest from './pages/Examination/Synthesis/SingleTest';
import SynthesisTest from './pages/Examination/Synthesis/synthesisTest';
import SynthesisInformation from './pages/Examination/Synthesis/SynthesisInformation';
import SynthesisTestDetail from './pages/Examination/Synthesis/SynthesisTestDetail';
import SynthesisComplete from './pages/Examination/Synthesis/SynthesisComplete';
import StoryTestPage from './pages/Assimilation/StoryTestPage';
import MyPage from "./pages/Mypage/MyPage";
import MyInquiryList from "./pages/Mypage/MyInquiryList";
import CallPayment from './pages/Assimilation/CallPayment';
import CallSinglePayment from './pages/Assimilation/CallSinglePayment';
import CallPaymentDetail from './pages/Assimilation/CallPaymentDetail';
import SeriesPayment from './pages/Assimilation/SeriesPayment';
import SeriesSinglePayment from './pages/Assimilation/SeriesSinglePayment';
import ShoppingCart from './pages/Assimilation/ShoppingCart';
import StoryInformation from './pages/Assimilation/StoryInformation';
import FaqList from "./pages/Mypage/FaqList";
import NoticeList from "./pages/Mypage/NoticeList";
import VideoPlayer from './pages/Assimilation/VideoPlayer';
import StoryComplete from './pages/Assimilation/StoryComplete';
import Setting from "./pages/setting/Setting";
import {Slide, ToastContainer} from "react-toastify";
import NoticeDetail from "./pages/Mypage/NoticeDetail";
import Withdrawal from "./pages/setting/withdrawal";
import PaymentResult from './pages/Payment/PaymentResult';
import useUserStore from './store/user/userStore';
import IdFindSuccess from './pages/Login/IdFindSuccess';
import IdFindFail from './pages/Login/IdFindFail';
import NewPassword from './pages/Login/NewPassword';
import PassWordSuccess from './pages/Login/PassWordSuccess';
import Terms from "./pages/terms/Terms";
import PrivacyPolicy from "./pages/terms/PrivacyPolicy";
import AfterVideoQuestions from './pages/Assimilation/AfterVideoQuestions';
import Report from './pages/Report/Report';
import FreeVideo from './pages/Main/freeVideo';
import CoverReport from './components/Roport/CoverReport';
import { getCookie } from './utils/cookies';
import { removeCookie } from './utils/cookies';

function App() {

  const { updateUserInfo, memberTokens } = useUserStore();

  useEffect(() => {
      const initializeAuth = () => {
          const accessToken = getCookie('access_token');
          const storedUserInfo = localStorage.getItem('userInfo');

          if (accessToken && storedUserInfo) {
              try {
                const parsedUserInfo = JSON.parse(storedUserInfo);
                updateUserInfo(parsedUserInfo);
                useUserStore.getState().updateTokens(accessToken); // memberTokens 업데이트 추가
              } catch (error) {
                  console.error('사용자 정보 파싱 에러:', error);
                  removeCookie('access_token');
                  localStorage.removeItem('userInfo');
              }
          }
      };

      initializeAuth();
  }, [updateUserInfo]);

  return (
    <Router>
      <div id="App" className="App">
        <div id="view_wrapper">
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                limit={5}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                closeButton={false}
                theme="dark"
                transition={Slide}
            />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/assimilation" element={<Assimilation/>}/>
            <Route path="/mindprescription" element={<MindPrescription/>}/>
            <Route path="/alert" element={<Alert/>}/>
            <Route path="/edit" element={<Edit/>}/>
            <Route path="/nicknameedit" element={<NicknameEdit/>}/>
            <Route path="/passwordchange" element={<PasswordChange/>}/>
            <Route path="/passwordsuccess" element={<PasswordSuccess/>}/>
            <Route path="/login" element={memberTokens ? <Navigate to="/" replace /> : <Login />}/>
            <Route path="/idfind" element={memberTokens ? <Navigate to="/" replace /> : <IdFind/>}/>
            <Route path="/passwordfind" element={<PasswordFind/>}/>
            <Route path="/join" element={memberTokens ? <Navigate to="/" replace /> : <Join/>}/>
            <Route path="/agreement" element={memberTokens ? <Navigate to="/" replace /> : <Agreement />}/>
            <Route path="/userinfoForm" element={memberTokens ? <Navigate to="/" replace /> :<UserInfoForm/>}/>
            <Route path="/joinsuccess" element={<JoinSuccess/>}/>
            <Route path="/joinfail" element={<JoinFail/>}/>
            <Route path="/intro" element={<Intro/>}/>
            <Route path="/generaltest" element={<GeneralTest/>}/>
            <Route path="/childrenpayment" element={<ChildrenPayment/>}/>
            <Route path="/childreninformation" element={<ChildrenInformation/>}/>
            <Route path="/generaltestdetail" element={<GeneralTestDetail/>}/>
            <Route path='/testcomplete' element={<TestComplete/>}/>
            <Route path="/synthesistest" element={<SynthesisTest/>}/>
            <Route path="/synthesispayment" element={<SynthesisPayment/>}/>
            <Route path="/singletest" element={<SingleTest/>}/>
            <Route path='/synthesisinformation' element={<SynthesisInformation/>}/>
            <Route path='/synthesistestdetail' element={<SynthesisTestDetail/>}/>
            <Route path='/synthesiscomplete' element={<SynthesisComplete/>}/>
            <Route path="/storytestpage" element={<StoryTestPage/>}/>
            <Route path="/my-inquiry" element={<MyInquiryList/>}>
              <Route index element={<Navigate to="/my-inquiry?type=inquiry" replace />} />
            </Route>
            <Route path="/my-list" element={<MyPage />}>
              <Route index element={<Navigate to="/my-list?type=test" replace />} />
            </Route>
            <Route path="/faq" element={<FaqList />}/>
            <Route path="/notice" element={<NoticeList />}>
              <Route path=":b_seq" element={<NoticeDetail />} />
            </Route>
            <Route path="/setting" element={<Setting />}/>
            <Route path="/withdrawal" element={<Withdrawal />}/>
            <Route path='/callpayment' element={<CallPayment/>}/>
            <Route path="/callsinglepayment" element={<CallSinglePayment/>}/>
            <Route path="/callpaymentdetail" element={<CallPaymentDetail/>}/>
            <Route path="/seriespayment" element={<SeriesPayment/>}/>
            <Route path="/seriesSinglepayment" element={<SeriesSinglePayment/>}/>
            <Route path="/shoppingcart" element={<ShoppingCart/>}/>
            <Route path="/storyinformation" element={<StoryInformation/>}/>
            <Route path="/videoplayer" element={<VideoPlayer/>}/>
            <Route path='/storycomplete' element={<StoryComplete/>}/>
            <Route path='/payment/result' element={<PaymentResult/>}/>
            <Route path="/idFindsuccess" element={<IdFindSuccess/>}></Route>
            <Route path="/idfindfail" element={<IdFindFail/>}></Route>
            <Route path="/newpassword" element={<NewPassword/>}></Route>
            <Route path="/newpassword/success" element={<PassWordSuccess/>}></Route>
            <Route path="/terms" element={<Terms/>}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
            <Route path="/aftervideoquestions" element={<AfterVideoQuestions/>}></Route>
            <Route path="/report" element={<Report/>}></Route>
            <Route path="/freeVideo" element={<FreeVideo/>}></Route>
            <Route path="/coverreport" element={<CoverReport/>}></Route>
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
