import React, {useRef, useState, useEffect, useMemo} from "react";
import Header from "../../components/Header/Header";
import Badge from "../../components/Badge/Badge";
import Call_arrow from "../../assets/img/call_arrow.png";
import Call_arrow2 from "../../assets/img/Call_arrow2.png";
import ButtonTab from "../../components/Tab/ButtonTab";
import {useLocation, useNavigate} from "react-router-dom";
import instance from "../../services/services";
import { STORY_TEST_URL, TOSS_PAYMENT_URL } from "../../utils/urls";
import { loadTossPayments } from '@tosspayments/payment-sdk';
import useUserStore from "../../store/user/userStore";

function CallPayment() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [storyData, setStoryData] = useState(null);
    const callArrowContainerRef = useRef(null);
    const { memberTokens } = useUserStore();


    useEffect(() => {
        const fetchStoryData = async () => {
            try {
                const response = await instance.post(STORY_TEST_URL, {
                    packageInfoChk: "Y"
                });

                if (response.data.code === 200) {
                    setStoryData(response.data.data);
                } else {
                    console.error('스토리 데이터 조회 실패:', response.data.message);
                }
            } catch (error) {
                console.error('스토리 데이터 조회 오류:', error);
            }
        };

        fetchStoryData();
    }, []);

    const handleBackClick = () => {
        if (queryParams.get('code') === 'PAY_PROCESS_CANCELED') {
            navigate('/');
        } else {
            navigate(-1);
        }
    };

    const handleImageClick = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const handleMouseDown = (e) => {
        const container = callArrowContainerRef.current;
        const startX = e.pageX - container.offsetLeft;
        const scrollLeft = container.scrollLeft;

        const handleMouseMove = (e) => {
            const x = e.pageX - container.offsetLeft;
            const walk = (x - startX) * 2;
            container.scrollLeft = scrollLeft - walk;
        };

        const handleMouseUp = () => {
            container.removeEventListener('mousemove', handleMouseMove);
            container.removeEventListener('mouseup', handleMouseUp);
            container.style.cursor = 'grab';
        };

        container.addEventListener('mousemove', handleMouseMove);
        container.addEventListener('mouseup', handleMouseUp);
        container.style.cursor = 'grabbing';
    };

    const handlePurchase = async () => {
        try {
            const storedToken = localStorage.getItem('accessToken') || memberTokens;

            if (!storedToken || !storyData?.allPackageGoods) {
                console.error('토큰 또는 상품 정보가 없습니다');
                navigate('/login');
                return;
            }

            const paymentRequestBody = {
                member_seq: "4",
                settlePrice: Number(storyData.allPackageGoods.price),
                order_kind: "story",
                use_point: null,
                mode: "choice",
                payment: "card",
                items: [
                    {
                        goods_seq: storyData.allPackageGoods.goods_seq,
                        option_seq: "",
                        ea: "1"
                    }
                ],
                validation_data: {
                    goods_name: storyData.allPackageGoods.goods_name,
                    inspection_title: "검사 유형 전체 구매",
                    original_price: storyData.allPackageGoods.price
                }
            };

            const response = await instance.post(TOSS_PAYMENT_URL, paymentRequestBody);

            if (response.data.code === 200) {
                const paymentInfo = response.data.data.data;
                const clientKey = process.env.NODE_ENV === 'development' 
                    ? 'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq'
                    : process.env.REACT_APP_TOSS_CLIENT_KEY;

                const tossPayments = await loadTossPayments(clientKey);
                await tossPayments.requestPayment('카드', {
                    amount: paymentInfo.amount,
                    orderId: paymentInfo.orderId,
                    orderName: paymentInfo.orderName,
                    customerName: paymentInfo.customerName,
                    successUrl: `${window.location.origin}/payment/result?goods_seq=${storyData.allPackageGoods.goods_seq}&order_kind=story&redirect_path=/my-list&redirect_params=type=test`,
                    failUrl: `${window.location.origin}/payment/result?fail_redirect_path=/callpayment&fail_redirect_params=goods_seq=${storyData.allPackageGoods.goods_seq}`
                });
            } else {
                throw new Error(response.data.message || '결제 요청이 실패했습니다.');
            }
        } catch (error) {
            console.error('결제 처리 중 에러:', error);
            navigate(`/callpayment?goods_seq=${storyData.allPackageGoods.goods_seq}`);
        }
    };

    return (
        <div className="CallPayment">
            <div className="content" style={{ marginBottom: "15vh" }}>
                <Header title="동화 검사" handleBack={handleBackClick} />
                <div className="info_wrapper">
                    <Badge badgeColor="primary-60" badgeText="동화" />
                    <div className="intro_age_group">
                        {storyData?.allPackageGoods.goods_name || "검사 유형 전체 구매"}
                    </div>
                    <div className="intro_call_info">
                        {storyData?.allPackageGoods.packageCnt || 60}권
                    </div>
                </div>

                {storyData?.packageInfo.map((item, index) => (
                    <div className="call_payment_wrapper" key={item.goods_seq} onClick={() => handleImageClick(index)}>
                        <div className="call_type_container">
                            <div className="call_type_info">
                                <div className="call_type_title">
                                    {item.goods_name}
                                </div>
                                <div className="call_type_count">
                                    <div className="call_type_details" style={{ fontSize: "15px" }}>
                                        {item.packageCnt}권
                                    </div>
                                </div>
                            </div>
                            <div className="call_arrow_container">
                                <img 
                                    src={expandedIndex === index ? Call_arrow2 : Call_arrow} 
                                    alt="Call_arrow" 
                                    className="Call_arrow_img" 
                                />
                            </div>
                        </div>
                        {expandedIndex === index && item.packageGoods && (
                            <div
                                className="call_img_data"
                                onMouseDown={handleMouseDown}
                                ref={callArrowContainerRef}
                            >
                                {item.packageGoods.map((goods) => (
                                    <div 
                                        key={goods.goods_seq}
                                        onClick={() => navigate('/seriesSinglepayment', {
                                            state: {
                                                goodsSeq: goods.goods_seq,
                                            }
                                        })}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <img 
                                            src={goods.mainImage} 
                                            alt={goods.goods_name} 
                                            className="Call_img" 
                                            style={{
                                                borderRadius: "20px",
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="payment_line"></div>
                    </div>
                ))}

                <div />
            </div>
            <ButtonTab 
                buttonText="전체 구매하기" 
                onClick={handlePurchase}
            />
        </div>
    );
}

export default CallPayment;
