import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import instance from '../../services/services';
import useUserStore from '../../store/user/userStore';

function PaymentResult() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { memberTokens, updateUserInfo } = useUserStore();

    useEffect(() => {
        const processSuccessPayment = async () => {
            try {
                const paymentKey = searchParams.get('paymentKey');
                const orderId = searchParams.get('orderId');
                const amount = searchParams.get('amount');
                const goodsSeq = searchParams.get('goods_seq');
                const orderKind = searchParams.get('order_kind');
                const redirectPath = searchParams.get('redirect_path');
                const redirectParams = searchParams.get('redirect_params');

                const response = await instance.post(
                    '/api/pay/payment_request_mobile',
                    { 
                        orderId, 
                        paymentKey, 
                        amount: Number(amount),
                        goods_seq: goodsSeq,
                        order_kind: orderKind
                    },
                    {
                        headers: { Authorization: `Bearer ${memberTokens}` }
                    }
                );

                if (response.data.code === 200) {
                    const responseGoodsSeq = response.data.data?.goods_seq || goodsSeq;
                    
                    let redirectUrl = redirectPath || '/';
                    
                    // 기본 파라미터 설정
                    const params = new URLSearchParams();
                    if (redirectParams) {
                        // redirectParams 문자열을 파싱하여 params에 추가
                        const additionalParams = new URLSearchParams(redirectParams);
                        additionalParams.forEach((value, key) => {
                            params.append(key, value);
                        });
                    }

                    // 경로별 추가 파라미터 설정
                    switch(redirectPath) {
                        case '/childrenInformation':
                        case '/storyinformation':
                        case '/synthesisinformation':
                            params.append('goods_seq', responseGoodsSeq);
                            params.append('orderId', orderId);
                            break;
                        case '/my-list':
                            // my-list는 redirectParams로 전달된 파라미터만 사용
                            break;
                        default:
                            break;
                    }

                    // 파라미터가 있는 경우에만 URL에 추가
                    const queryString = params.toString();
                    if (queryString) {
                        redirectUrl += `?${queryString}`;
                    }

                    window.location.replace(redirectUrl);
                } else {
                    throw new Error(response.data.message || '결제 처리에 실패했습니다.');
                }
            } catch (error) {
                console.error('결제 승인 중 오류:', error);
                const failRedirectPath = searchParams.get('fail_redirect_path');
                const failRedirectParams = searchParams.get('fail_redirect_params');
                
                let failUrl = failRedirectPath || '/';
                if (failRedirectParams) {
                    failUrl += `?${failRedirectParams}`;
                }
                
                window.location.replace(failUrl);
            }
        };

        const processFailPayment = () => {
            const failRedirectPath = searchParams.get('fail_redirect_path');
            const failRedirectParams = searchParams.get('fail_redirect_params');
            
            let failUrl = failRedirectPath || '/';
            if (failRedirectParams) {
                failUrl += `?${failRedirectParams}`;
            }
            
            window.location.replace(failUrl);
        };

        const isSuccess = searchParams.get('paymentKey') && 
                        searchParams.get('orderId') && 
                        searchParams.get('amount');

        if (isSuccess) {
            processSuccessPayment();
        } else {
            processFailPayment();
        }
    }, [navigate, searchParams, memberTokens, updateUserInfo]);

    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh' 
        }}>
            <h2>결제를 처리중입니다...</h2>
        </div>
    );
}

export default PaymentResult;