import React, { useState, useEffect } from "react";
import Button from "../../../components/Button/Button";
import Test_Img from "../../../assets/img/test_img.png";
import { useNavigate, useLocation } from "react-router-dom";
import OutPop from "../../../components/Popup/OutPop";
import Header from "../../../components/Header/Header";
import QuestionList from '../../../components/QuestionTypes/QuestionComponent';
import instance from "../../../services/services";
import { SYNTHESIS_QUESTION_URL, SYNTHESIS_ANSWER_URL } from "../../../utils/urls";

const GeneralTestDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [questions, setQuestions] = useState([]); // 문항 데이터
    const [tab, setTab] = useState('tab1');
    const [totalTabs, setTotalTabs] = useState(0); // 탭 개수
    const [numberCheck, setNumberCheck] = useState({}); // 문제 체크 상태
    const [showImage, setShowImage] = useState(false);
    const [showQuestions, setShowQuestions] = useState(true);
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);
    const [reportSeq, setReportSeq] = useState(null);
    const [goodsSeq, setGoodsSeq] = useState(null); // 완료 페이지에 goods_seq 값을 전달 하기 하여 선언

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await instance.post(SYNTHESIS_QUESTION_URL, {
                    order_seq: location.state.order_seq,
                    item_option_seq: location.state.item_option_seq,
                    goods_seq: location.state.goods_seq
                });

                if (response.data.code === -201) {
                    alert(response.data.message);
                    navigate('/');
                    return;
                }

                if (response.data.code === 200) {
                    setReportSeq(response.data.data.report_info.report_seq);
                    setGoodsSeq(response.data.data.report_info.goods_seq);
                    const questionData = response.data.data.questions || [];
                    setQuestions(questionData);
                    
                    const tabCount = Math.ceil(questionData.length / 10); // 탭에 10개의 문항 표출
                    setTotalTabs(tabCount);
                    
                    const initialNumberCheck = {};
                    for (let i = 1; i <= tabCount; i++) {
                        initialNumberCheck[`tab${i}`] = {};
                    }
                    setNumberCheck(initialNumberCheck);
                }
            } catch (error) {
                console.error("Error fetching questions:", error);
            }
        };

        fetchQuestions();
    }, [location.state, navigate]);

    const getQuestionType = (answerType) => {
        switch(answerType) {
            case "2":
                return "binary";
            case "3":
                return "threePoint";
            case "5":
                return "fivePoint";
            default:
                return "fivePoint";
        }
    };

    const getCurrentQuestions = () => {
        if (!questions.length) return [];
        const tabNumber = parseInt(tab.replace('tab', ''));
        const startIndex = (tabNumber - 1) * 10;
        const endIndex = startIndex + 10;
        
        const currentQuestions = questions.slice(startIndex, endIndex);
        return currentQuestions.map(q => ({
            count: `Q${q.sort_num}`, // 문항 번호
            content: q.qst_content, // 문항 title
            qst_seq: q.qst_seq, // 문항 시퀀스
            type: q.answer_type // answer값에 따라 문항 타입
        }));
    };

    const handleNumberCheck = (questionIndex, selectedCheck, currentTab) => {
        console.log('Selected:', questionIndex, selectedCheck, currentTab);
        setNumberCheck(prev => {
            const newState = {
                ...prev,
                [tab]: {
                    ...prev[tab],
                    [questionIndex]: selectedCheck
                }
            };
            console.log('New state:', newState);
            return newState;
        });
    };

    const allAnswered = (tabAnswers) => {
        if (!tabAnswers) return false;
        const currentQuestions = getCurrentQuestions();
        const answeredCount = Object.keys(tabAnswers).length;
        console.log('Answered count:', answeredCount, 'Total questions:', currentQuestions.length);
        return answeredCount === currentQuestions.length;
    };

    const handleButtonClick = async () => {
        if (showQuestions) {
            if (allAnswered(numberCheck[tab])) {
                // 현재 탭의 답변만 수집
                const currentTabNumber = parseInt(tab.replace('tab', ''));
                const startIndex = (currentTabNumber - 1) * 10;
                
                const currentTabAnswers = Object.entries(numberCheck[tab]).map(([questionIndex, value]) => {
                    const question = questions[startIndex + parseInt(questionIndex)];
                    return {
                        qst_seq: parseInt(question.qst_seq),
                        choice_val: parseInt(value),
                        short_answer: ""
                    };
                });

                try {
                    // 현재 탭의 답변만 서버로 전송
                    const response = await instance.post(SYNTHESIS_ANSWER_URL, {
                        report_seq: parseInt(reportSeq),
                        answers: currentTabAnswers
                    });
                    
                    if (response.data.code === 200) {
                        if (tab === `tab${totalTabs}`) {
                            // 마지막 탭이면 완료 페이지로 이동
                            navigate('/testcomplete', { state: { goods_seq: goodsSeq } });
                        } else {
                            // 다음 탭으로 이동하기 전에 이미지 표시
                            setShowQuestions(false);
                            setShowImage(true);
                        }
                    }
                } catch (error) {
                    console.error("Error submitting answers:", error);
                }
            }
        } else {
            // 이미지 화면에서 다음 탭으로 이동
            setShowImage(false);
            setTab(`tab${parseInt(tab.replace('tab', '')) + 1}`);
            setShowQuestions(true);
        }
    };

    const handleModalYesClick = () => {
        navigate("/");
    };

    const handleBackClick = () => {
        setIsOutPopOpen(true);
    };

    return (
        <div className="generalTestDetail">
            <div className="content">
                <Header title="일반 검사" handleBack={handleBackClick}/>
                <div className="content_body">
                    <div style={{ display: "flex", gap: "4px" }}>
                        {[...Array(totalTabs)].map((_, index) => (
                            <div
                                key={`tab${index + 1}`}
                                className={`tab ${tab === `tab${index + 1}` ? "activeTab" : ""}`}
                                onClick={() => setTab(`tab${index + 1}`)}
                            />
                        ))}
                    </div>

                    <div className="tabContent">
                        <div>
                            {showQuestions && (
                                <QuestionList
                                    questions={getCurrentQuestions()}
                                    selectedCheck={numberCheck[tab]}
                                    onNumberCheck={(index, check) => handleNumberCheck(index, check, tab)}
                                    questionType={getQuestionType(questions[0]?.answer_type)}
                                />
                            )}
                            {showImage && (
                                <div className="image-overlay">
                                    <img className="Test_Img" src={Test_Img} alt="설명" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="Edit_btn_change">
                    <Button 
                        label="다음" 
                        variant="btn_next" 
                        className={showImage || (showQuestions && allAnswered(numberCheck[tab])) ? "active" : "disabled"} 
                        onClick={handleButtonClick} 
                    />
                </div>
                <OutPop
                    isOpen={isOutPopOpen}
                    onClose={() => setIsOutPopOpen(false)}
                    onYesClick={handleModalYesClick}
                />
            </div>
        </div>
    );
};

export default GeneralTestDetail;