import React, { useState, useEffect } from 'react';
import instance from '../../services/services';
import { INSPECTION_REPORT_URL } from "../../utils/urls";
import socialImage from "../../assets/report_user/social.jpg";
import learningImage from "../../assets/report_user/learning.jpg";
import familyImage from "../../assets/report_user/family.jpg";
import physicalImage from "../../assets/report_user/physical.jpg";
import positiveImage from "../../assets/report_user/positive.jpg";
import generalImage from "../../assets/report_user/general.jpg";

const ReportCover = ({ reportData }) => {
    const [coverData, setCoverData] = useState(null);
    console.log(reportData, ':::reportData');

    useEffect(() => {
        const fetchCoverData = async () => {
            try {
                const response = await instance.post(INSPECTION_REPORT_URL, {
                    report_seq: reportData.report_seq
                });

                if (response.data.code === 200) {
                    setCoverData(response.data.data);
                    console.log("Cover Data:", response.data.data);
                }
            } catch (error) {
                console.error("Error fetching cover data:", error);
            }
        };

        if (reportData.report_seq) {
            fetchCoverData();
        }
    }, [reportData.report_seq]);

    const getCoverImage = (goodsSeq, goodsType) => {
        if (goodsType === 'general') {
            return generalImage;
        }

        switch (parseInt(goodsSeq)) {
            case 19: return socialImage;
            case 21: return learningImage;
            case 20: return familyImage;
            case 18: return physicalImage;
            case 22: return positiveImage;
            default: return familyImage;
        }
    };

    const textStyle = {
        position: 'absolute',
        color: '#000',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'left',
        padding: '5px 10px',
        background: 'transparent',
        width: '200px'
    };

    return (
        <div className="report-cover-container" style={{ 
            position: 'relative',
            width: '595px',
            height: '842px',
            margin: '0 auto'
        }}>
            <img 
                src={getCoverImage(reportData?.goods_seq, reportData?.goods_type)}
                alt="리포트 표지" 
                style={{ 
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                }}
            />
            
            {coverData && (
                <div className="overlay-data" style={{ 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    height: '100%' 
                }}>
                    {reportData.goods_type === 'general' ? ( // 일반동화 일경우
                        <>
                            {/* 검사일 */}
                            <div style={{
                                ...textStyle,
                                top: '82.3%',
                                left: '22%',
                                width: '150px',
                            }}>
                                {coverData.report_info?.regist_date ? 
                                    new Date(coverData.report_info.regist_date).toLocaleDateString('ko-KR').replace(/\. /g, '.') : 
                                    new Date().toLocaleDateString('ko-KR')
                                }
                            </div>

                            {/* 이름 */}
                            <div style={{
                                ...textStyle,
                                top: '74.3%',
                                left: '55%',
                                width: '150px',
                            }}>
                                {coverData.report_info?.test_user_name || ''}
                            </div>

                            {/* 성별 */}
                            <div style={{
                                ...textStyle,
                                top: '78.3%',
                                left: '55%',
                                width: '150px',
                            }}>
                                {coverData.report_info?.gender === 'male' ? (
                                    <span style={{ color: '#000' }}>남자</span>
                                ) : coverData.report_info?.gender === 'female' ? (
                                    <span style={{ color: '#000' }}>여자</span>
                                ) : null}
                            </div>

                            {/* 연령 */}
                            <div style={{
                                ...textStyle,
                                top: '82.3%',
                                left: '55%',
                                width: '150px',
                            }}>
                                {coverData.report_info?.age_txt ? `만 ${coverData.report_info.age_txt}세` : ''}
                            </div>
                        </>
                    ) : (
                        <>
                            {/* 검사일 */}
                            <div style={{
                                ...textStyle,
                                top: '70.3%',
                                left: '75%',
                                width: '150px',
                            }}>
                                {coverData.report_info?.regist_date ? 
                                    new Date(coverData.report_info.regist_date).toLocaleDateString('ko-KR').replace(/\. /g, '.') : 
                                    new Date().toLocaleDateString('ko-KR')
                                }
                            </div>

                            {/* 이름 */}
                            <div style={{
                                ...textStyle,
                                top: '74%',
                                left: '75%',
                                width: '150px',
                            }}>
                                {coverData.report_info?.test_user_name || ''}
                            </div>

                            {/* 성별 */}
                            <div style={{
                                ...textStyle,
                                top: '78%',
                                left: '75%',
                                width: '150px',
                            }}>
                                {coverData.report_info?.gender === 'male' ? (
                                    <span style={{ color: '#000' }}>남자</span>
                                ) : coverData.report_info?.gender === 'female' ? (
                                    <span style={{ color: '#000' }}>여자</span>
                                ) : null}
                            </div>

                            {/* 연령 */}
                            <div style={{
                                ...textStyle,
                                top: '82%',
                                left: '75%',
                                width: '150px',
                            }}>
                                {coverData.report_info?.age_txt ? `만 ${coverData.report_info.age_txt}세` : ''}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

const CoverReport = ({ reportData, listData }) => {
    return (
        <div className="report-container">
            {reportData.goods_type !== "story" && <ReportCover reportData={reportData} />}
        </div>
    );
};

export default CoverReport;