import { useEffect } from 'react';
import { loginUser } from '../../services/Auth/auth';
import useUserStore from '../../store/user/userStore';
import { getCookie, setCookie } from '../../utils/cookies';

export const useAutoLogin = () => {
    useEffect(() => {
        const checkAutoLogin = async () => {
            const autoLoginEnabled = localStorage.getItem('autoLogin') === 'true';
            const savedId = localStorage.getItem('savedId');
            const savedPassword = localStorage.getItem('savedPassword');
            const accessToken = getCookie('access_token');

            if (autoLoginEnabled && savedId && savedPassword && !accessToken) {
                try {
                    const response = await loginUser(savedId, atob(savedPassword), true);
                    
                    if (response?.data?.code === 200) {
                        const { access_token } = response.data.data;
                        const decodedPayload = JSON.parse(atob(access_token.split('.')[1]));
                        
                        // 쿠키에 토큰 저장
                        setCookie('access_token', access_token, 30);
                        
                        const userInfo = {
                            ...decodedPayload,
                            user_name: decodedPayload.username
                        };
                        
                        localStorage.setItem('userInfo', JSON.stringify(userInfo));
                        useUserStore.getState().updateTokens(access_token);
                        useUserStore.getState().updateUserInfo(userInfo);
                    }
                } catch (error) {
                    console.error('자동 로그인 실패:', error);
                    localStorage.removeItem('autoLogin');
                    localStorage.removeItem('savedId');
                    localStorage.removeItem('savedPassword');
                }
            }
        };

        checkAutoLogin();
    }, []);
}; 