// 신체 건강 영역 타이틀 (goods_seq:18)
const healthQuestions = [
    {
        content: "각 문항을 읽고, 내가 음식을 먹을 때의 모습과 가장 일치하는 답변을 고르세요.", // 문항 설명
        displayBeforeQuestion: 11 // 문항 표시 위치(위로 들어가게)
    },
    {
        content: "각 문항을 읽고, 내가 생활을 할 때의 모습과 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 43
    },
    {
        content: "각 문항을 읽고, 내가 스마트폰을 사용할 때의 각 문항에 대해 얼마나 많이 사용하는지 체크해주세요.",
        displayBeforeQuestion: 89
    },
    {
        content: "각 문항을 읽고, 내가 운동을 할 때의 모습과 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 96
    }
];
// 사회성 영역 (goods_seq:19)
const socialQuestions = [
    {
        content: "다음의 감정 중 내가 친구들을 생각할 때의 느낌에 해당되는 것을 체크해 주세요.",
        displayBeforeQuestion: 1
    },
    {
        content: "각 문항을 읽고 내가 친구를 생각 할 때의 모습과 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 36
    },
    {
        content: "각 문항을 읽고 내가 어른을 생각 할 때의 모습과 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 78
    },
    {
        content: "각 문항을 읽고 내가 친구와 있을 때 어떻게 행동하는지 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 96
    }
];

// 가족 관계 영역 (goods_seq:20)
const familyQuestions = [
    {
        content: "다음의 감정 중 내가 가족들(형제 포함)을 생각할 때의 느낌에 해당되는 것을 체크해주세요.",
        displayBeforeQuestion: 1
    },
    {
        content: "각 문항을 읽고 내가 가족을 생각할 때의 모습과 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 36
    },
    {
        content: "각 문항을 읽고 내가 가족을 생각할 때의 모습과 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 66
    },
    {
        content: "각 문항을 읽고 내가 가족을 생각할 때의 모습과 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 76
    },
    {
        content: "각 문항을 읽고 내가 가족(형제, 자매 등)을 생각할 때의 모습과 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 106
    },
];

// 학습 영역 (goods_seq:21)
const studyQuestions = [
    {
        content: "다음의 감정 중 내가 공부를 생각할 때의 떠오르는 느낌과 떠오르지 않는 느낌을 선택해주세요.",
        displayBeforeQuestion: 1
    },
    {
        content: "각 문항을 읽고 내가 공부를 할 때의 모습과 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 36
    },
    {
        content: "각 문항을 읽고 내가 공부를 할 때의 모습과 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 72
    },
    {
        content: "각 문항을 읽고 내가 공부를 할 때의 모습과 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 107
    },
    

];

// 긍정 성격 영역 (goods_seq:22)
const personalityQuestions = [
    {
        content: "다음의 감정 중 최근 약 2주간 느낌과 가장 일치하는 느낌에는 '그렇다', 일치하지 않는 느낌은 '아니다'를 선택해주세요.",
        displayBeforeQuestion: 1
    },
    {
        content: "각 문항을 읽고 나의 모습과 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 36
    },
    {
        content: "각 문항을 읽고 다음 중 내가 쉬는 날 어떤 활동을 하고 싶은지 고르세요.",
        displayBeforeQuestion: 108
    },
    {
        content: "각 문항을 읽고 다음 중 내가 쉬는 날 주로 어떤 활동을 하는지 가장 일치하는 답변을 고르세요.",
        displayBeforeQuestion: 148
    },
    {
        content: "각 문항을 읽고 나의 생각과 가장 일치하는 답변의 숫자를 고르세요.",
        displayBeforeQuestion: 232
    },

];

// goods_seq에 따라 적절한 문항 설명을 반환하는 함수
const getQuestionDescriptions = (goodsSeq) => { // goods_seq값에 따라서 값이 잘 들어가게 case 설정
    switch(goodsSeq) {
        case "18": // 신체 건강 영역
            return healthQuestions;
        case "19": // 사회성 영역
            return socialQuestions;
        case "20": // 가족 관계 영역
            return familyQuestions;
        case "21": // 학습 영역
            return studyQuestions;
        case "22": // 긍정 성격 영역
            return personalityQuestions;
        default:
            return [];
    }
};

export { getQuestionDescriptions };
