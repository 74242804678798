import React, {useCallback, useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Icon_setting from '../../assets/icon/ic_setting.png';
import Icon_vector from '../../assets/icon/icon-n500-Rarrow.png';
import Youtube from '../../assets/img/Layer_1.png';
import Insta from '../../assets/img/inst.png';
import FaceBook from '../../assets/img/facebook.png';
import Blog from '../../assets/img/blog.png';
import Close from '../../assets/img/ic_Close_1.png';
import Profile from '../../assets/img/default_profile.png';
import Arrow from '../../assets/img/ic_arrow.png';
import Main_arrow from '../../assets/icon/main_arrow.png';
import {CSSTransition} from 'react-transition-group';
import useUserStore from '../../store/user/userStore';
import {toast} from "react-toastify";
import AlarmButton from "../Button/AlarmButton";
import useCommonStore from "../../store/common/useCommonStore";
import {getAlarmCount} from "../../services/alarm/alarm";
import useMyInfo from "../../hooks/common/useMyInfo";

function NavBar({onClose, isOpen}) {
    const navbarRef = useRef();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const {userInfo, memberTokens} = useUserStore();
    const {setAlarmCnt} = useCommonStore()
    const {myInfo} = useMyInfo()

    const getAlarmTotCount = useCallback(async () => {
        try {
            const response = await getAlarmCount();
            if (response.status === 200) {
                setAlarmCnt(response.data.count); // 알람 개수 업데이트
            }
        } catch (error) {
            console.error("알람 개수를 가져오는 중 오류 발생:", error);
        }
    }, [setAlarmCnt])

    useEffect(() => {
        if (isLoggedIn) {
            getAlarmTotCount();
        }
    }, [isLoggedIn, getAlarmTotCount]);
    // 메뉴 아이템 배열
    const menuItems = [
        {label: "내 심리 검사", value: myInfo.inspectionCnt || 0, url: '/my-list?type=test'},
        {label: "내 리포트", value: myInfo.reportCnt || 0, url: '/my-list?type=report'},
        {label: "결제정보", value: true, url: '/my-list?type=payment'},
        {label: "나의 문의/신청", value: true, url: '/my-inquiry?type=inquiry'}
    ];

    const notifications = [
        {label: "공지사항", icon: true, url: '/notice'},
        {label: "FAQ", icon: false, url: '/faq'},
        {label: "회사소개", icon: false, url: '/intro'}
    ];




    useEffect(() => {
        // userInfo 상태가 변경될 때마다 로그인 상태 업데이트
        setIsLoggedIn(!!memberTokens);
    }, [memberTokens]);

    // 로그인 상태 및 사용자 정보 확인
    useEffect(() => {
        const storedUserInfo = localStorage.getItem('userInfo');
        if (storedUserInfo) {
            try {
                const parsedUserInfo = JSON.parse(storedUserInfo);
                setIsLoggedIn(!!parsedUserInfo.user_name);
            } catch (error) {
                console.error('사용자 정보 파싱 에러:', error);
                setIsLoggedIn(false);
            }
        }
    }, [userInfo]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const handleMyPage = (url) => {
        if (isLoggedIn) {
            navigate(url);
        } else {
            toast('로그인이 필요합니다.')
            navigate('/login')
        }
    };

    return (
        <>
            {isOpen && <div className='overlay'></div>}
            <CSSTransition
                in={isOpen}
                timeout={300}
                classNames="slide"
                unmountOnExit
            >
                <div className="navbar">
                    <div className='menu' ref={navbarRef}>
                        <div className="menu_header">
                            <div className="close_icon" onClick={onClose} style={{cursor: 'pointer'}}>
                                <img className="icon close_icon" src={Close} alt="닫기"/>
                            </div>
                            <div className="menu_icons">
                                <AlarmButton/>
                                <Link to='/setting'>
                                    <img className="Icon_alarm" src={Icon_setting} alt="설정 아이콘"/>
                                </Link>
                            </div>
                        </div>

                        <div className="user_info">
                            {isLoggedIn && memberTokens ? (
                                <div className="user_info_header">
                                    <div className="user_name">{userInfo.nickname}</div>
                                    <Link
                                        to="/edit"
                                        state={{email: myInfo.email}}
                                        style={{textDecoration: "none"}}
                                    >
                                        <div className="edit_info_wrapper">
                                            <div className="edit_info">내 정보 수정</div>
                                            <div>
                                                <img className='text_icon_vector' src={Icon_vector} alt="아이콘"/>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ) : (
                                <div>
                                    <div className="user_info_header">
                                        <Link to="/login" style={{textDecoration: "none"}}>
                                            <div className='login_section'>
                                                <div className='main_login'>로그인 해주세요</div>
                                                <div className='main_arrow'>
                                                    <img className='main_arrow_img' src={Main_arrow} alt='Main_arrow'/>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )}

                            <div className="profile_image">
                                <img src={Profile} alt="프로필"/>
                            </div>
                        </div>
                        <div className="line_height"/>

                        <div className="user_info_section">
                            {menuItems.map((info, index) => (
                                <div className="info_item" key={index} onClick={() => handleMyPage(info.url)}>
                                    <div className="info_label">{info.label}</div>
                                    <div className="info_value">
                                        {info.value !== true ? info.value : <img src={Arrow} alt="arrow"/>}
                                        {info.value !== null && info.value !== true &&
                                            <span className="arrow-icon"><img src={Arrow} alt="arrow"/></span>}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="line_height"/>

                        <div className="notifications group_line">
                            {notifications.map((notification, index) => (
                                <div className="notification_item group_item" key={index}>
                                    <Link to={notification.url} style={{textDecoration: "none"}}
                                          className={`notification_label ${notification.icon ? 'new_icon' : ''}`}>
                                        {notification.label}
                                    </Link>
                                </div>
                            ))}
                        </div>

                        <div className="social_icons">
                            <a href="https://www.instagram.com/iqvitamin/" target="blank">
                                <button type="button"><img className="social_icon" src={Insta} alt="인스타그램"/></button>
                            </a>
                            <a href="https://www.facebook.com/kdccare0907/" target="blank">
                                <button type="button"><img className="social_icon" src={FaceBook} alt="페이스북"/></button>
                            </a>
                            <a href="https://blog.naver.com/kdccare" target="blank">
                                <button type="button"><img className="social_icon" src={Blog} alt="블로그"/></button>
                            </a>
                            <a href="https://www.youtube.com/@iqvitamin" target="blank">
                                <button type="button"><img className="social_icon" src={Youtube} alt="카카오톡"/></button>
                            </a>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </>
    );
}

export default NavBar;
