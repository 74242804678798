import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Badge from "../../components/Badge/Badge";
import Ellipse from "../../assets/img/Ellipse 100.png";
import Button from "../../components/Button/Button";
import OutPop from "../../components/Popup/OutPop";
import Report from "../Report/Report";
import RetestPop from "../../components/Popup/RetestPop";
import { useNavigate, useLocation } from "react-router-dom";

function StoryComplete(){
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.state, ':::location.state');
    const report_seq = location.state?.report_seq;
    const goods_name = location.state?.goods_name;
    const large_image = location.state?.large_image;
    const [test] = useState(true);
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);
    const [isRetestPopOpen, setIsRetestPopOpen] = useState(false);
    const [showReport, setShowReport] = useState(false);

    const handleBackClick = () => {
        setIsOutPopOpen(true); // OutPop 열기
    };

    const handleReportClick = () => {
        setShowReport(true); // Report 내용 표시
    };

    const handleRetestClick = () => {
        setIsRetestPopOpen(true); // RetestPop 열기
    };

    const handleModalYesClick = () => {
        navigate("/");
    };

    const handleReportButtonClick = () => {
        navigate('/my-list?type=report');
    };

    
    return(
        <div className="StoryComplete">
            <div className="content">
            <Header title={goods_name} handleBack={handleBackClick} />
                {!showReport ? (
                    <>
                        <div className="content_body">           
                            <div className="complete_wrapper">
                                <Badge badgeColor="primary-60" badgeText="동화" />
                                <div className="intro_payment_group">동화 검사를 완료했어요</div>
                                <div className="report_results">리포트 결과를 확인해 보세요</div>
                            </div>

                            <div className="CompleteImg_container">
                                <img src={large_image} alt="CompleteImg" className="CompleteImg"/>
                            </div>

                            <div className="refund_info_container">
                                <div className="refund_info_item">
                                    <div className="ellipse_container">
                                        <img src={Ellipse} alt="Ellipse" className="Ellipse"/>
                                    </div>
                                    <div className="refund_text">리포트 확인 전에는 무제한 재검사가 가능합니다.</div>
                                </div>
                                <div className="refund_info_item">
                                    <div className="ellipse_container">
                                        <img src={Ellipse} alt="Ellipse" className="Ellipse"/>
                                    </div>
                                    <div className="refund_text">동화 리포트는 3회까지만 제공됩니다.</div>
                                </div>
                            </div>
                            

                        </div>

                        <div className="button_section">
                        {test ? (
                            <>
                            <Button  
                                label="재검사하기" 
                                variant="btn_join" 
                                onClick={handleRetestClick} 
                            />
                            <Button 
                                label="리포트 미리보기" 
                                variant="btn_complete" 
                                onClick={handleReportClick} 
                            />
                            </>
                        ) : (

                            <Button  
                                label="동화 다시보기" 
                                variant="btn_join" 
                            />
                        )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="content_body" style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            padding: '20px'
                        }}>           
                            <div className="promo-banner" style={{
                                width: '100%',
                                textAlign: 'center',
                                marginBottom: '24px'
                            }}>
                                <div className="promo-title" style={{
                                }}>리포트 미리보기</div>
                                <div className="promo-description" style={{
                                }}>
                                    동화 검사 결과는 내 리포트 페이지에서<br/>
                                    ‘pdf 다운받기’를 통해 확인 가능합니다
                                </div>
                            </div>

                            <div style={{
                                transform: 'scale(0.7)',
                                transformOrigin: 'top center',
                                display: 'flex',
                                justifyContent: 'center',
                                overflow: 'auto',
                                height: '100%',
                            }}>
                                <Report reportData={{ report_seq: report_seq }} />
                            </div>
                        </div>
                        <div className="button_section">
                            <Button
                                label="내 리포트로 이동"
                                variant="btn_complete"
                                onClick={handleReportButtonClick}
                            />
                        </div>
                    </>
                )}

                <OutPop
                    isOpen={isOutPopOpen}
                    onClose={() => setIsOutPopOpen(false)}
                    onYesClick={handleModalYesClick}
                />
                {/* <ReportPop
                    isOpen={isReportPopOpen}
                    onClose={() => setIsReportPopOpen(false)}
                    onYesClick={handleModalYesClick}
                /> */}
                <RetestPop
                    isOpen={isRetestPopOpen}
                    onClose={() => setIsRetestPopOpen(false)}
                    onYesClick={handleModalYesClick}
                />



            </div>
        </div>
    )
}

export default StoryComplete;