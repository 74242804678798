// 선택지 타입별 스타일 설정
// const overlayStyles = {
//     2: {  // 2지선다 (가로)
//         position: 'absolute',
//         width: '18%',
//         height: '13%',
//         maxWidth: '550px',
//         cursor: 'pointer',
//         zIndex: 10,
//         pointerEvents: 'auto',
//         transform: 'translate(-50%, -50%)'
//     },
//     3: {  // 3지선다 (가로)
//         position: 'absolute',
//         width: '14%',
//         height: '11.5%',
//         maxWidth: '550px',
//         cursor: 'pointer',
//         zIndex: 10,
//         pointerEvents: 'auto',
//         transform: 'translate(-50%, -50%)'
//     },
//     4: {  // 4지선다 (세로)
//         position: 'absolute',
//         width: '3.1%',
//         height: '4.1%',
//         maxWidth: '550px',
//         cursor: 'pointer',
//         zIndex: 10,
//         pointerEvents: 'auto',
//         transform: 'translate(449%, -50%)'
//     },
//     5: {  // 5지선다 (세로)
//         position: 'absolute',
//         width: '3%',
//         height: '3%',      // 세로 배치에 맞게 크기 조정
//         maxWidth: '550px',
//         cursor: 'pointer',
//         zIndex: 10,
//         pointerEvents: 'auto',
//         transform: 'translate(530%, 0%)'
//     },
//     6: {  // 6지선다 (세로)
//         position: 'absolute',
//         width: '3%',
//         height: '3.9%',
//         maxWidth: '550px',
//         cursor: 'pointer',
//         zIndex: 10,
//         pointerEvents: 'auto',
//         transform: 'translate(487%, -50%)'
//     }
// };

// answer_type에 따라 선택지를 렌더링하는 함수
const renderChoiceOverlay = (question, onAnswer, answeredQuestions, goods_seq) => {
    const choiceCount = parseInt(question.answer_type);

    const getPosition = (index, count) => {
        if (count === 4) {
            const positions4 = {
                70: [
                    { left: '96%', top: '44.7%' },
                    { left: '96%', top: '52%' },
                    { left: '96%', top: '55%' },
                    { left: '96%', top: '65%' }
                ],
                65: [
                    { left: '38%', top: '47.8%' },
                    { left: '54%', top: '67%' },
                    { left: '38%', top: '47.8%' },
                    { left: '54%', top: '58.5%' }
                ],
                77: [
                    { left: '96%', top: '40%' },
                    { left: '96%', top: '45%' },
                    { left: '96%', top: '50.5%' },
                    { left: '96%', top: '55.7%' }
                ],
                default: { left:'0%',top: 42.3 + (index * 5.3) }
            };

            return positions4[goods_seq]?.[index] || positions4.default;
        }

        if (count === 5) {
            const positions5 = {
                56: [
                    { left: '70.2%', top: '58%' },
                    { left: '82.5%', top: '58%' },
                    { left: '95%', top: '58%' },
                    { left: '107.5%', top: '58%' },
                    { left: '120%', top: '58%' }
                ],
                default: {
                    left: '2%',
                    top: 44 + (index * 4.5)
                }
            };
            return positions5[goods_seq]?.[index] || positions5.default;
        }


        const positions = {
            2: { left: 39 + (index * 22.5), top: '58%' },
            3: { left: 31 + (index * 19), top: '57.8%' },
            6: { left: '0%', top: 42.1 + (index * 4.45) }
        };
        return positions[count];
    };

    const getOverlayStyle = (choiceCount, goods_seq) => {
        const baseStyles = {
            2: {  // 2지선다 (가로)
                position: 'absolute',
                width: '18%',
                height: '13%',
                maxWidth: '550px',
                cursor: 'pointer',
                zIndex: 10,
                pointerEvents: 'auto',
                transform: 'translate(-50%, -50%)'
            },
            3: {  // 3지선다 (가로)
                position: 'absolute',
                width: '13.5%',
                height: '12.4%',
                maxWidth: '550px',
                cursor: 'pointer',
                zIndex: 10,
                pointerEvents: 'auto',
                transform: 'translate(-50%, -50%)'
            },
            4: {  // 4지선다 (세로)
                position: 'absolute',
                width: '3.1%',
                height: '3.5%',
                maxWidth: '550px',
                cursor: 'pointer',
                zIndex: 10,
                pointerEvents: 'auto',
                transform: 'translate(449%, -50%)'
            },
            5: {  // 5지선다 (세로)
                position: 'absolute',
                width: '3%',
                height: '3%',
                maxWidth: '550px',
                cursor: 'pointer',
                zIndex: 10,
                pointerEvents: 'auto',
                transform: 'translate(530%, 0%)'
            },
            6: {  // 6지선다 (세로)
                position: 'absolute',
                width: '3%',
                height: '3.3%',
                maxWidth: '550px',
                cursor: 'pointer',
                zIndex: 10,
                pointerEvents: 'auto',
                transform: 'translate(487%, -50%)'
            }
        };

        // 4지선다일 때 goods_seq에 따른 스타일 분기
        if (choiceCount === 4) {
            switch(goods_seq) {
                case '70':
                    return {
                        ...baseStyles[4],
                        transform: 'translate(-1370%, -50%)',
                        width: '3.1%',
                        height: '3.5%'
                    };
                case '65':
                    return {
                        ...baseStyles[4],
                        transform: 'translate(175%, -49%)',
                        width: '10.5%',
                        height: '9.9%'
                    };
                case '77':
                    return {
                        ...baseStyles[4],
                        transform: 'translate(-1878%, 54%)',
                        width: '4.2%',
                        height: '4.2%'
                    };
                default:
                    return baseStyles[4];
            }
        }

        if (choiceCount === 5) {
            switch(goods_seq) {
                case '56':
                    return {
                        ...baseStyles[4],
                        transform: 'translate(-500%, -50%)',
                        width: '10%',
                        height: '9.5%'
                    };
                default:
                    return baseStyles[4];
            }
        }

        return baseStyles[choiceCount];
    };

    return (
        <div key={question.qst_seq}>
            {[...Array(choiceCount)].map((_, index) => {
                const position = getPosition(index, choiceCount);
                const choiceValue = index + 1;

                return (
                    <div
                        key={`${question.qst_seq}-${index}`}
                        onClick={() => onAnswer(question.qst_seq, index)}
                        className={`choice-overlay choice-${choiceCount}`}
                        data-goods-seq={goods_seq}  // data-goods-seq 속성 추가
                        style={{
                            ...getOverlayStyle(choiceCount, goods_seq),
                            left: typeof position.left === 'string' 
                                ? position.left 
                                : `${position.left}%`,
                            top: typeof position.top === 'string' 
                                ? position.top 
                                : `${position.top}%`,
                            backgroundColor: answeredQuestions[question.qst_seq] === choiceValue
                                ? '#01bcc8'
                                : '#01bcc873'
                        }}
                    />
                );
            })}
        </div>
    );
};

// 메인 렌더링 함수
export const renderQuestionOverlay = (question, onAnswer, answeredQuestions, goods_seq) => {
    // answer_type이 2~6 사이인 경우에만 렌더링
    const answerType = parseInt(question.answer_type);
    if (answerType >= 2 && answerType <= 6) {
        return renderChoiceOverlay(question, onAnswer, answeredQuestions, goods_seq);
    }
    return null;
}; 