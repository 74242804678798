import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {loginUser} from "../../services/Auth/auth";
import useUserStore from "../../store/user/userStore";
import {useAutoLogin} from "../../hooks/login/useAutoLogin";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import loginRectangle from "../../assets/img/loginRectangle.png";
import NaverLogin from "../../assets/img/ic_sns_naver.png";
import KakaoLogin from "../../assets/img/ic_sns_KakaoTalk.png";
import AppleLogin from "../../assets/img/ic_sns_AppleTalk.png";
import MainLogo from "../../assets/img/logo.png";
import {useAppleLogin, useKakaoLogin, useNaverLogin, useSnsUserLogin} from "../../hooks/login/snsLogin";
import {KAKAO_LOGIN_REDIRECT_URI, NAVER_LOGIN_REDIRECT_URI} from "../../utils/urls";
import { passwordvisible } from "../../utils/passwordvisible";
import { setCookie } from "../../utils/cookies";

function Login() {
    const naverLoginInfo = useNaverLogin(NAVER_LOGIN_REDIRECT_URI); // 네이버 로그인 유저정보
    const kakaoLoginInfo = useKakaoLogin(KAKAO_LOGIN_REDIRECT_URI); // 카카오 로그인 유저정보
    const appleLoginInfo = useAppleLogin('https://www.iqvitamininside.com/login')//애플 로그인
    const {loginSnsUser} = useSnsUserLogin()
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [isShowPw, setShowPw] = useState(false);
    const [loginForm, setLoginForm] = useState({
        id: '',
        password: '',
        autoLogin: false
    });

    // 자동 로그인 훅 사용
    useAutoLogin();

    // 컴포넌트 마운트 시 저장된 로그인 정보 불러오기
    useEffect(() => {
        const autoLoginEnabled = localStorage.getItem('autoLogin') === 'true';
        const savedId = localStorage.getItem('savedId');
        const savedPassword = localStorage.getItem('savedPassword');

        if (autoLoginEnabled && savedId && savedPassword) {
            setLoginForm(prev => ({
                ...prev,
                id: savedId,
                password: atob(savedPassword),
                autoLogin: true
            }));
        }
    }, []);

    const handleInputChange = (e) => {
        const {name, value, type, checked} = e.target;
        setLoginForm(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };


    useEffect(() => {
        if (naverLoginInfo?.snsInfo?.snsUserInfo) {

            loginSnsUser("naver", {idToken: naverLoginInfo.snsInfo.snsUserInfo.id_token});
        }
    }, [naverLoginInfo.snsInfo, loginSnsUser]);

    useEffect(() => {
        if (kakaoLoginInfo?.snsInfo?.snsUserInfo) {
            loginSnsUser("kakao", {idToken: kakaoLoginInfo.snsInfo.snsUserInfo.id_token});
        }
    }, [kakaoLoginInfo.snsInfo, loginSnsUser]);

    useEffect(() => {
        if (appleLoginInfo?.snsInfo?.snsUserInfo) {
            loginSnsUser("apple", {idToken: appleLoginInfo.snsInfo.snsUserInfo.id_token});
        }
    }, [appleLoginInfo.snsInfo, loginSnsUser]);


    const handleLogin = async () => {
        try {
            const {id, password, autoLogin} = loginForm;
            const response = await loginUser(id, password, autoLogin);

            if (response?.data?.code === 200) {
                const {access_token} = response.data.data;
                const decodedPayload = JSON.parse(atob(access_token.split('.')[1]));
                const savedEmail = localStorage.getItem('userEmail'); // 로컬스토리지에서 이메일값 받아오기

                const userInfo = {
                    ...decodedPayload,
                    user_name: decodedPayload.username,
                    email: savedEmail || response.data.data.email
                };

                // 토큰을 30일 유효기간을 가진 쿠키로 저장
                setCookie('access_token', access_token, 30);

                if (autoLogin) {
                    localStorage.setItem('autoLogin', 'true');
                    localStorage.setItem('savedId', id);
                    localStorage.setItem('savedPassword', btoa(password));
                }

                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                useUserStore.getState().updateTokens(access_token);
                useUserStore.getState().updateUserInfo(userInfo);

                navigate('/');
            } else {
                // 서버에서 200이 아닌 코드를 반환한 경우
                setError(response?.data?.message || '로그인에 실패했습니다.');
            }
        } catch (error) {
            // alert 제거하고 에러 메시지만 설정
            setError(error.response?.data?.message || '아이디 또는 비밀번호를 확인해주세요.');
        }
    };

    const isValidForm = loginForm.id.trim() && loginForm.password.trim();

    return (
        <div className="login">
            <div className="content">
                <Header title="로그인" handleBack={() => window.history.back()}/>

                {/* 로고 섹션 */}
                <div className="login_logo_container">
                    <div className="login_logo_image">
                        <img src={MainLogo} alt="MainLogo" className="MainLogo"/>
                    </div>
                    <div className="login_logo_text_container">
                        <div className="login_logo_text">우리 아이 몸맘뇌 케어</div>
                        <div className="login_logo_title">아이큐비타민</div>
                    </div>
                </div>

                <div className="content_body" style={{marginTop: "40px", padding: "0px 24px"}}>
                    {/* 로그인 폼 */}
                    <div className="nickname_input_container default_input" style={{marginTop: "0px"}}>

                        <input
                            type="text"
                            name="id"
                            className="login_input"
                            value={loginForm.id}
                            onChange={handleInputChange}
                            placeholder="아이디 입력"
                        />

                        <div className="comb_input" style={{marginTop: "8px"}}>
                            <input
                                type={isShowPw ? 'text' : 'password'}
                                name="password"
                                className="login_input"
                                value={loginForm.password}
                                onChange={handleInputChange}
                                placeholder="비밀번호 입력"

                            />
                            <button 
                                    type="button" 
                                    className={`input_btn show_pw_btn ${isShowPw ? 'show' : ''}`}
                                    onClick={() => setShowPw(!isShowPw)}
                                    onMouseDown={(e) => passwordvisible(e, loginForm.password)}
                                ></button>
                        </div>

                        {error &&
                            <div className={`error_message nickname`}>{error}
                            </div>}
                        {/* 자동 로그인 체크박스 */}
                        <div className="default_chk_box auto_login">
                            <input name="autoLogin" type="checkbox" id="auto-login"
                                    onChange={handleInputChange}></input>
                            <label className="auto_login_title" htmlFor="auto-login">
                                <span>자동 로그인</span>
                            </label>
                        </div>
                    </div>

                    {/* 계정 관련 링크 */}
                    <div className="account_actions">
                        <Link to="/idfind" className="account_action">아이디 찾기</Link>
                        <img src={loginRectangle} alt="구분선"/>
                        <Link to="/passwordfind" className="account_action">비밀번호 찾기</Link>
                        <img src={loginRectangle} alt="구분선"/>
                        <Link to="/join" className="account_action">회원가입</Link>
                    </div>

                    {/* SNS 로그인 섹션 */}
                    <div className="login_footer">
                        <div className="sns-login-container">
                            <span>SNS 계정으로 로그인</span>
                        </div>
                        <div className="sns_login_buttons">
                            <div className="sns_login_button" onClick={() => appleLoginInfo.handleAppleLogin()}>
                                <img className="NaverLogin" src={AppleLogin} alt="Naver 로그인"/>
                            </div>
                            <div className="sns_login_button" onClick={() => naverLoginInfo.handleNaverLogin()}>
                                <img className="NaverLogin" src={NaverLogin} alt="Naver 로그인"/>
                                <div id="naverIdLogin" style={{display: 'none'}}></div>
                            </div>
                            <div className="sns_login_button" onClick={() => kakaoLoginInfo.handleKakaoLogin()}>
                                <img className="KakaoLogin" src={KakaoLogin} alt="Kakao 로그인"/>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 로그인 버튼 */}
                <div className="Edit_btn_change">
                    <Button
                        label="로그인"
                        variant="btn_next"
                        onClick={handleLogin}
                        disabled={!isValidForm}
                        className={isValidForm ? 'active' : 'disabled'}
                    />
                </div>
            </div>
        </div>
    );
}

export default Login;
